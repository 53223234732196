import { TransactedValue, User } from '.';

export type TTransactedSalesCommissionId = number;

export interface TransactedSalesCommission {
  id: TTransactedSalesCommissionId;
  transacted_value: TransactedValue;
  user?: User;
  name?: string;
  percent_rate: number;
}
