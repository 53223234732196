import dayjs from 'dayjs';
import { Accordion, AccordionPanel, Button, DataTable, Pagination } from 'grommet';
import { DocumentText, Print } from 'grommet-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { ApiError, IncentiveStatementService, TListIncentiveStatementsRequest } from '/src/api';
import { Box, Card, CardBody, CardHeader, DataTableHeader, ExternalLink, LoadingSpinner, Text } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Client, IncentiveStatement, TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { tooltipMessages } from '/src/lib/tooltips';
import { TResponseMetadata } from '/src/lib/types';
import { getQuarterFromDateString, pxToRem } from '/src/utils';

export type StatementListProps = {
  clientId: TClientId;
  client?: Client;
  title?: string;
  id?: string;
};

export const StatementList: React.FC<StatementListProps> = (props) => {
  /** Props **/
  const { clientId, title, id } = props;

  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);

  const [incentiveStatements, setIncentiveStatements] = useState<IncentiveStatement[]>();
  const [metadata, setIncentiveStatementsMetadata] = useState<TResponseMetadata>();
  const [request, _] = useState<TListIncentiveStatementsRequest>({
    client_id: clientId,
  });

  /** Computed **/
  const hasIncentiveStatements = !!incentiveStatements?.length;

  /** Methods **/
  const fetchIncentiveStatements = async (page?: number) => {
    if (!clientId || !userStore.user) return;

    try {
      const { meta, data } = await IncentiveStatementService.listIncentiveStatements({
        ...request,
        page: page || request.page,
      });
      const statements = data.filter(
        (statement: IncentiveStatement) => statement.clientReportingPeriod?.is_finalized ?? true
      );
      setIncentiveStatements(statements);
      setIncentiveStatementsMetadata(meta);
      setCurrentPage(meta.current_page);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listIncentiveStatements.error);
    } finally {
      setIsLoading(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (!incentiveStatements) {
      fetchIncentiveStatements(currentPage);
    }
  }, [incentiveStatements, currentPage]);

  useEffect(() => {
    if (!isLoading && metadata && currentPage !== metadata.current_page) {
      setIsLoading(true);
      fetchIncentiveStatements(currentPage);
    }
  }, [isLoading, metadata, currentPage]);

  /** Render **/
  return (
    <Box id={id} direction="row" gap="medium" fill="horizontal">
      <Card>
        <CardHeader title={title || 'Statement List'} icon={<DocumentText size="24px" color="brand" />}></CardHeader>
        <CardBody pad="none" gap="none">
          {isLoading && <LoadingSpinner />}
          {!isLoading && (
            <Box elevation="small">
              <Accordion activeIndex={activeIndex} onActive={(activeIndexes) => setActiveIndex(activeIndexes[0])}>
                <AccordionPanel
                  label={
                    <Box pad="medium">
                      <Text size="large">Incentive Statements</Text>
                    </Box>
                  }
                >
                  <DataTable
                    pad={{ horizontal: '1.5rem' }}
                    columns={[
                      {
                        property: 'clientReportingPeriod.end_reporting_quarter',
                        size: '30%',
                        header: <DataTableHeader title="REPORTING PERIOD" />,
                        render: (incentiveStatement: IncentiveStatement) => (
                          <Box margin={{ vertical: pxToRem('14px') }}>
                            <Text size="medium" fontFamily="Lato, sans-serif">
                              {getQuarterFromDateString(
                                incentiveStatement.clientReportingPeriod?.end_reporting_quarter
                              )}
                            </Text>
                          </Box>
                        ),
                      },
                      {
                        property: 'statement_date',
                        size: '30%',
                        header: <DataTableHeader title="STATEMENT DATE" />,
                        render: (incentiveStatement: IncentiveStatement) => (
                          <Box margin={{ vertical: pxToRem('14px') }}>
                            <Text size="medium" fontFamily="Lato, sans-serif">
                              {dayjs(incentiveStatement.statement_date).format('MM/DD/YYYY')}
                            </Text>
                          </Box>
                        ),
                      },
                      {
                        property: 'summary',
                        sortable: false,
                        size: '20%',
                        render: (incentiveStatement: IncentiveStatement) => (
                          <ExternalLink to={`/clients/${clientId}/statements/incentive/${incentiveStatement.id}`}>
                            <Box direction="row" gap="xsmall" align="center">
                              <Print size="22px" />
                              <Text fontFamily="Lato, sans-serif">Summary (Printable)</Text>
                            </Box>
                          </ExternalLink>
                        ),
                      },
                      {
                        property: 'details',
                        sortable: false,
                        size: '20%',
                        render: (incentiveStatement: IncentiveStatement) => (
                          <Box margin={{ vertical: pxToRem('14px') }}>
                            <ExternalLink
                              to={`/clients/${clientId}/statements/incentive/${incentiveStatement.id}/details`}
                            >
                              <Box direction="row" gap="xsmall" align="center">
                                <Button
                                  plain
                                  tip={tooltipMessages.viewPrintableStatement}
                                  icon={<Print size="22px" />}
                                />
                                <Text fontFamily="Lato, sans-serif">Details (Printable)</Text>
                              </Box>
                            </ExternalLink>
                          </Box>
                        ),
                      },
                    ]}
                    data={incentiveStatements}
                    sortable
                    sort={{
                      property: 'reporting_quarter',
                      direction: 'desc',
                    }}
                    background={['light-6', 'white']}
                    border={{ color: 'light-2', side: 'top', size: 'small' }}
                  />
                </AccordionPanel>
              </Accordion>
            </Box>
          )}
          {!hasIncentiveStatements && !isLoading && (
            <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
              <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                No Statements found.
              </Text>
            </Box>
          )}
          {!!metadata && !!currentPage && !!setCurrentPage && (
            <Box pad="1.5rem" flex="grow">
              <Pagination
                alignSelf="center"
                size="small"
                page={currentPage}
                step={metadata?.per_page}
                numberItems={metadata?.total}
                onChange={(e) => setCurrentPage(e.page)}
              />
            </Box>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};
