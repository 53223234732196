import { Button } from 'grommet';
import { AddCircle, Trash } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { ApiError, ProgramService } from '/src/api';
import { Box, FormCardSection, MaskedInput, ProgramSelect, Select, Text } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Region, TProgramId, TRegionId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { FormErrors, ProgramCommissionDataForm, SelectOptions } from '/src/lib/types';

export type ClientCommissionsProps = {
  regionId: TRegionId;
  id?: string;
  commissions: ProgramCommissionDataForm[];
  setCommissions: (values: ProgramCommissionDataForm[]) => void;
  formErrors: Record<string, FormErrors>;
};

export const ClientCommissions: React.FC<ClientCommissionsProps> = observer((props) => {
  const { regionId, id, commissions, setCommissions, formErrors } = props;

  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /** Refs **/
  const defaultFormValues = useRef<ProgramCommissionDataForm>({
    program_id: NaN,
    is_percent_rate: false,
    rate: '',
  });

  /** State **/
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(false);
  const [programOptions, setProgramOptions] = useState<SelectOptions<TProgramId>>();
  const [selectedPrograms, setSelectedPrograms] = useState<TProgramId[]>([]);

  /** Computed **/
  const region = globalStore.regions.find((r) => r.id === regionId);
  const isAddDisabled = programOptions && commissions.length === programOptions.length;

  /** Methods **/

  /**
   * Update form data
   * @param key
   * @param value
   */
  const updateFormValue = (key: string, index: number, value: any) => {
    const updatedValues = commissions;
    updatedValues[index] ??= defaultFormValues.current;
    updatedValues[index][key] = value;
    setCommissions(updatedValues);
    formErrors[index] ??= {} as FormErrors;
    if (formErrors[index][key]) formErrors[index][key] = '';
    if (key === 'program_id' && !!value && !Number.isNaN(value)) {
      setSelectedPrograms(updatedValues.map((c) => c.program_id));
    }
  };

  /**
   * Handles adding a Commission to the list.
   */
  const handleAddCommission = () => {
    const updatedValues = commissions;
    updatedValues.push({ ...defaultFormValues.current });
    setCommissions(updatedValues);
  };

  /**
   * Handles removing the Commission at the given list index.
   */
  const handleRemoveCommission = (index: number) => {
    const updatedValues = commissions;
    updatedValues.splice(index, 1);
    setCommissions(updatedValues);
    setSelectedPrograms(updatedValues.map((c) => c.program_id));
  };

  /**
   * Fetch Programs list
   */
  const fetchPrograms = async (region: Region) => {
    if (!userStore.user) return;

    try {
      setIsLoadingPrograms(true);

      const programs = await ProgramService.list({ country_id: region.country_id });

      let options = programs.map((program) => ({
        label: program.name,
        value: program.id,
      }));

      if (commissions.length) {
        const existingProgramIds = commissions.map((v) => v.program_id);
        options = options.filter(({ value }) => !existingProgramIds.includes(value));
      }

      setProgramOptions(options);
      setSelectedPrograms([]);

      if (!commissions.length) handleAddCommission();
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listPrograms.error);
    } finally {
      setIsLoadingPrograms(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (region) {
      fetchPrograms(region);
    }
  }, [region]);

  /** Render **/
  return (
    <FormCardSection
      id={id}
      title="Program Commissions"
      button={
        <Button
          plain
          icon={<AddCircle size="18px" />}
          onClick={() => handleAddCommission()}
          tip="Add Commission"
          disabled={!region || isAddDisabled}
        />
      }
    >
      {commissions.map((commission, index) => (
        <Box row gap="1rem">
          <ProgramSelect
            id="program_id"
            label="Program"
            placeholder="Choose..."
            emptySearchMessage={`Choose a state or province to see available Programs.`}
            value={commission.program_id}
            setValue={(value) => updateFormValue('program_id', index, value)}
            error={formErrors[index]?.['program_id']}
            regionId={region?.id}
            required
            fill="horizontal"
            options={programOptions?.filter(
              (o) => !selectedPrograms.includes(o.value) || o.value === commission.program_id
            )}
          />
          <Select
            id="is_percent_rate"
            label="Commission Type"
            value={commission.is_percent_rate ? 'Percentage' : 'Fixed'}
            setValue={(value) => {
              updateFormValue('is_percent_rate', index, value === 'Percentage');
              updateFormValue('rate', index, '');
            }}
            options={['Fixed', 'Percentage']}
            error={formErrors[index]?.['is_percent_rate']}
            required
            fill="horizontal"
          />
          <MaskedInput
            id="rate"
            label={`Commission Rate${!commission.is_percent_rate ? ' ($/kWh)' : ''}`}
            value={commission.rate}
            setValue={(value) => updateFormValue('rate', index, value)}
            error={formErrors[index]?.['rate']}
            componentType={commission.is_percent_rate ? 'percent' : 'number'}
            isActiveLabel
            type="number"
            required
            fill="horizontal"
          />
          <Button
            plain
            icon={<Trash size="18px" color="red" />}
            onClick={() => handleRemoveCommission(index)}
            tip="Remove Commission"
            disabled={commissions.length === 1}
          />
        </Box>
      ))}

      {!region && (
        <Box align="center" justify="center">
          <Text size="medium" fontFamily="Lato, sans-serif">
            {`To set Commissions, choose a state or province in the Corporate Address section above.`}
          </Text>
        </Box>
      )}
    </FormCardSection>
  );
});
