import { DataTable, Pagination, ResponsiveContext } from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import { ApiError, FacilityService, TListFacilitiesRequest } from '/src/api';
import {
  AddButton,
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  DataTableHeader,
  DataTableItem,
  FacilityIcon,
  Filters,
  Link,
  LoadingSpinner,
  SearchInput,
  Text,
} from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Facility, RoleTypes, TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { EntityStatus, TResponseMetadata } from '/src/lib/types';
import { getIsMobile, pxToRem } from '/src/utils';
import dayjs from 'dayjs';

export type FacilityListProps = {
  clientId: TClientId;
  id?: string;
  title?: string;
  facilities?: Facility[];
  fetchFacilities?: (page?: number) => void;
  metadata?: TResponseMetadata;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  showAddButton?: boolean;
  showFilters?: boolean;
  showSearchInput?: boolean;
  hideHeader?: boolean;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  boxProps?: BoxProps;
};

export const FacilityList: React.FC<FacilityListProps> = (props) => {
  const {
    clientId,
    id,
    title,
    showFilters,
    showSearchInput,
    showAddButton,
    isLoading: propIsLoading,
    setIsLoading: propSetIsLoading,
    facilities: propFacilities,
    fetchFacilities: propFetchFacilities,
    currentPage: propCurrentPage,
    setCurrentPage: propSetCurrentPage,
    metadata: propMetadata,
    hideHeader,
    boxProps,
  } = props;

  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const screenSize = useContext(ResponsiveContext);

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [lastQuery, setLastQuery] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [facilities, setFacilities] = useState<Facility[]>();
  const [metadata, setFacilitiesMetadata] = useState<TResponseMetadata>();
  const [request, setRequest] = useState<TListFacilitiesRequest>({
    client_id: clientId,
  });
  const [filters, setFilters] = useState<Record<string, any>>({});

  /** Computed **/
  const _currentPage = propCurrentPage ?? currentPage;
  const _isLoading = propIsLoading ?? isLoading;
  const _facilities = propFacilities ?? facilities;
  const _metadata = propMetadata ?? metadata;

  const isMobile = getIsMobile(screenSize);
  const hasFacilities = !!_facilities?.length;

  /** Methods **/
  const _setIsLoading = propSetIsLoading ?? setIsLoading;
  const _setCurrentPage = propSetCurrentPage ?? setCurrentPage;

  const fetchFacilities = async (page?: number) => {
    if (!clientId || Number.isNaN(clientId) || !userStore.user) return;

    try {
      const { meta, data } = await FacilityService.list({ ...request, page: page || request.page });
      setFacilities(data);
      setFacilitiesMetadata(meta);
      _setCurrentPage(meta.current_page);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listFacilities.error);
    } finally {
      _setIsLoading(false);
      setIsFiltering(false);
      setIsSearching(false);
    }
  };

  const _fetchFacilities = propFetchFacilities ?? fetchFacilities;

  const search = (query?: string) => {
    setRequest({
      ...request,
      page: 1,
      [searchField.toLowerCase()]: query,
    });
    setLastQuery(query ?? '');
    setIsSearching(true);
  };

  const applyFilters = () => {
    setRequest({ ...request, ...filters });
    setIsFiltering(true);
  };

  const clearFilters = () => {
    setRequest({ client_id: clientId, page: 1 });
    setFilters({});
    setIsFiltering(true);
  };

  /** Effects **/
  useEffect(() => {
    if (isSearching || isFiltering) _fetchFacilities();
  }, [isSearching, isFiltering]);

  useEffect(() => {
    if (!_facilities) {
      if (!!Object.keys(filters).length) applyFilters();
      else _fetchFacilities(_currentPage);
    }
  }, [_facilities, _currentPage, filters]);

  useEffect(() => {
    if (!_isLoading && _metadata && _currentPage !== _metadata.current_page) {
      _setIsLoading(true);
      _fetchFacilities(_currentPage);
    }
  }, [_isLoading, _metadata, _currentPage]);

  /** Render **/
  const renderFacilityContact = (facility: Facility) => {
    const contacts = facility.users.filter((user) => user.role.id === RoleTypes.FacilityAdmin);

    return contacts[0] ? (
      <Link to={`/users/${contacts[0].id}`}>
        <Box fontFamily="Lato, sans-serif" margin={{ vertical: '1rem' }}>
          <Text size="medium" color="accent-1" weight={700} lineHeight="1rem">
            {contacts[0].name}
          </Text>
          <Text size="small" color="accent-1" lineHeight="1.25rem">
            {contacts[0].email}
          </Text>
          <Text size="small" color="accent-1" lineHeight="1rem">
            {contacts[0].phone ?? '(555) 123-4567'}
          </Text>
        </Box>
      </Link>
    ) : (
      <Text>—</Text>
    );
  };

  const renderFleetOverview = (facility: Facility) => {
    if (!facility.num_equipment_per_category.length) {
      return (
        <Box>
          <Text>-</Text>
        </Box>
      );
    }

    let hasItem = false;

    const overview = (
      <Box
        direction={isMobile ? 'column' : 'row'}
        wrap
        gap={isMobile ? '3px' : '6px'}
        margin={{ vertical: isMobile ? pxToRem(12) : undefined }}
      >
        {facility.num_equipment_per_category.map(({ category_name, num_equipment }) => {
          if (!category_name || !num_equipment) return;
          hasItem = true;
          return category_name && num_equipment ? (
            <Box
              key={category_name}
              direction="row"
              margin={{ vertical: pxToRem(3) }}
              border={{ side: 'all', size: 'small', color: 'light-2' }}
              background="white"
              gap={pxToRem(12)}
              pad={{ vertical: pxToRem(4), left: pxToRem(10), right: pxToRem(6) }}
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0 10px 10px -10px' }}
              round={pxToRem(4)}
            >
              <Box justify="center" flex={isMobile ? true : undefined}>
                <Text size={pxToRem(12)} lineHeight={pxToRem(12)}>
                  {category_name}
                </Text>
              </Box>
              <Box align="center" justify="center">
                <Box
                  style={{ minWidth: pxToRem(28) }}
                  pad={pxToRem(2.8)}
                  round={pxToRem(4)}
                  align="center"
                  justify="center"
                  background="accent-1"
                >
                  <Text color="white" weight={500} size={pxToRem(12)} lineHeight={pxToRem(12)}>
                    {num_equipment}
                  </Text>
                </Box>
              </Box>
            </Box>
          ) : undefined;
        })}
      </Box>
    );
    return hasItem ? (
      overview
    ) : (
      <Box>
        <Text>-</Text>
      </Box>
    );
  };

  return (
    <Box id={id} direction="row" gap="medium" fill="horizontal">
      <Card {...boxProps}>
        {!hideHeader && (
          <CardHeader title={title || 'Facility List'} icon={<FacilityIcon />}>
            {showSearchInput && searchQuery !== undefined && setSearchQuery !== undefined && (
              <SearchInput
                searchQuery={searchQuery}
                placeholder="Search by name"
                setSearchQuery={setSearchQuery}
                onSearch={search}
                isSearching={isSearching}
              />
            )}
            {!!clientId && !Number.isNaN(clientId) && showAddButton && userStore.isAdminUser && (
              <AddButton label="Add Facility" targetUrl={`/clients/${clientId}/facilities/create`} />
            )}
          </CardHeader>
        )}
        <CardBody pad="none" gap="none">
          {(_isLoading || isSearching || isFiltering) && <LoadingSpinner />}
          {!_isLoading && !isSearching && !isFiltering && (
            <Box elevation="small">
              <DataTable
                id="facility_list_data_table"
                pad={{ horizontal: '1.5rem' }}
                columns={[
                  {
                    property: 'id',
                    primary: true,
                    size: '22%',
                    header: <DataTableHeader title="ADDRESS" />,
                    render: (facility: Facility) => (
                      <Box pad={{ vertical: '1rem' }}>
                        <Link
                          to={`/clients/${
                            !!clientId && !Number.isNaN(clientId) ? clientId : facility.client_id ?? ''
                          }/facilities/${facility.id}`}
                          textDecoration="none"
                        >
                          <Box>
                            <Text
                              size="medium"
                              weight={700}
                              color="accent-1"
                              fontFamily="Lato, sans-serif"
                              lineHeight="1.25rem"
                            >
                              {facility.address_line1}
                            </Text>
                            <Text size="small" color="accent-1" fontFamily="Lato, sans-serif" lineHeight="1rem">
                              {facility.address_city},{' '}
                              {
                                (facility.address_region ?? globalStore.getRegionById(facility.address_region_id))
                                  ?.short_code
                              }{' '}
                              {facility.address_post_code}
                            </Text>
                          </Box>
                        </Link>
                      </Box>
                    ),
                  },
                  {
                    property: 'is_active',
                    header: <DataTableHeader title="STATUS" />,
                    render: (facility: Facility) => (
                      <DataTableItem value={facility.is_active ? EntityStatus.Active : EntityStatus.Retired} />
                    ),
                  },
                  {
                    property: 'contact.name',
                    size: '16%',
                    header: <DataTableHeader title="CONTACT" />,
                    render: renderFacilityContact,
                  },
                  {
                    property: 'program.name',
                    size: '10%',
                    header: <DataTableHeader title="Program" />,
                    render: (facility: Facility) => (
                      <Text size="medium" fontFamily="Lato, sans-serif">
                        {facility.program?.name}
                      </Text>
                    ),
                  },
                  {
                    property: 'utility.name',
                    size: '20%',
                    header: <DataTableHeader title="Utility" />,
                    render: (facility: Facility) => (
                      <Text size="medium" fontFamily="Lato, sans-serif">
                        {facility.utility?.name}
                      </Text>
                    ),
                  },
                  {
                    property: 'fleet_overview',
                    size: '30%',
                    sortable: false,
                    header: <DataTableHeader title="FLEET OVERVIEW" />,
                    align: 'start',
                    render: (facility: Facility) => renderFleetOverview(facility),
                  },
                ]}
                data={_facilities}
                sortable
                background={['light-6', 'white']}
                border={{ color: 'light-2', side: 'bottom', size: 'small' }}
              />
            </Box>
          )}
          {!hasFacilities && !_isLoading && !isSearching && (
            <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
              <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                No facilities found{lastQuery ? ` with the name "${lastQuery}"` : ''}.
              </Text>
            </Box>
          )}
          {!!_metadata && !!_currentPage && (!!propSetCurrentPage || !!setCurrentPage) && (
            <Box pad="1.5rem" flex="grow">
              <Pagination
                alignSelf="center"
                size="small"
                page={_currentPage}
                step={_metadata?.per_page}
                numberItems={_metadata?.total}
                onChange={(e) => (propSetCurrentPage ? propSetCurrentPage(e.page) : setCurrentPage(e.page))}
              />
            </Box>
          )}
        </CardBody>
      </Card>
      {showFilters && (
        <Filters
          isFiltering={isFiltering}
          onSubmit={() => applyFilters()}
          onClear={() => clearFilters()}
          filters={[
            {
              label: 'Program',
              value: request.program_id ?? NaN,
              setValue: (value) => setFilters({ ...filters, program_id: value }),
              options: [],
            },
            {
              label: 'Utility',
              value: request.utility_id ?? NaN,
              setValue: (value) => setFilters({ ...filters, utility_id: value }),
              options: [],
            },
            {
              label: 'State/Region',
              value: request.address_region_id ?? NaN,
              setValue: (value) => setFilters({ ...filters, address_region_id: value }),
              options: globalStore.regions
                .filter((region) => !!region.short_code)
                .map((region) => ({ label: region.short_code ?? '', value: region.id })),
            },
          ]}
        />
      )}
    </Box>
  );
};
