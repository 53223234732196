import { Button, Layer } from 'grommet';
import { Close, StatusWarning } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiError, EquipmentService } from '/src/api';
import { EntityIcon, FormCard, InfoBox, Select, Text } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Equipment, ModelName } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { DateString, FormErrors, FormMetadata, SelectOptions } from '/src/lib/types';
import { getFormErrors, getModelLabel } from '/src/utils';

export type RetireEquipmentModalProps = {
  equipment: Equipment;
  setIsVisible: (isVisible: boolean) => void;
  setShouldRefresh: (shouldRefresh: boolean) => void;
  reportingPeriodOptions: SelectOptions<DateString>;
};

export type TRetireEquipmentModalForm = {
  last_active_reporting_quarter: DateString;
};

export const RetireEquipmentModal: React.FC<RetireEquipmentModalProps> = observer((props) => {
  const { equipment, setIsVisible, setShouldRefresh, reportingPeriodOptions } = props;

  /** Stores **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /** State **/
  const [isRetiring, setIsRetiring] = useState(false);

  const [formValues, setFormValues] = useState<TRetireEquipmentModalForm>({
    last_active_reporting_quarter: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  /** Computed **/
  const isLoading = !reportingPeriodOptions;
  const canRetire =
    userStore.isClientAdmin || userStore.isFacilityAdmin
      ? !!userStore.user?.facilities.find((f) => f.id === equipment.facility_id)
      : userStore.isFuseAdmin;

  /** Methods **/
  const updateFormValue = (key: string, value: any) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  const retireEquipment = async () => {
    if (isRetiring) return;

    const formFields: FormMetadata = {
      last_active_reporting_quarter: {
        label: 'Last Reporting Period',
        required: true,
      },
    };

    const errors = getFormErrors(formFields, formValues);
    if (errors) {
      setFormErrors(errors);
      return;
    }

    try {
      setIsRetiring(true);
      await EquipmentService.retire({
        id: equipment.id,
        last_active_reporting_quarter: formValues.last_active_reporting_quarter,
      });
      setShouldRefresh(true);
      setIsVisible(false);
      toast.success(toastMessages.retireEquipment.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.retireEquipment.error);
    } finally {
      setIsRetiring(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (!canRetire) setIsVisible(false);
  }, [canRetire]);

  /** Render **/
  return (
    <Layer onEsc={() => setIsVisible(false)} onClickOutside={() => setIsVisible(false)} background="transparent">
      <FormCard
        width="30rem"
        title={`Retire ${getModelLabel(equipment, ModelName.Equipment)}`}
        icon={<EntityIcon entityName={ModelName.Equipment} />}
        onSubmit={() => retireEquipment()}
        isLoading={isLoading || isRetiring}
        hideRequiredText
        saveButtonLabel="RETIRE"
        saveButtonLoadingLabel="RETIRING..."
        headerChildren={[
          <Button
            key="close"
            pad="none"
            onClick={() => setIsVisible(false)}
            icon={<Close size="20px" />}
            tip="Close"
          />,
        ]}
      >
        <InfoBox gap="1rem">
          <StatusWarning />
          <Text fontFamily="Lato, sans-serif">
            Warning: Retiring this Equipment will discard any Usage data for the current period.
          </Text>
        </InfoBox>
        <Select
          id="last_active_reporting_quarter"
          label="Last Reporting Period"
          placeholder="Select Period..."
          value={formValues.last_active_reporting_quarter}
          setValue={(value) => updateFormValue('last_active_reporting_quarter', value)}
          options={reportingPeriodOptions}
          error={formErrors['last_active_reporting_quarter']}
          required
          fill="horizontal"
        />
      </FormCard>
    </Layer>
  );
});
