import { observer } from 'mobx-react-lite';
import React from 'react';
import { Box, UsagesDataTableHeader, UsagesDataTableRow } from '/src/components';
import { Equipment, EquipmentUsage } from '/src/lib/models';
import { DataTableCell, MeteredEquipmentUsageDataForm, UnmeteredEquipmentUsageDataForm } from '/src/lib/types';

export type UsagesDataTableProps = {
  equipmentUsages: EquipmentUsage[];
  tableCells: DataTableCell[];
  cellWidth?: string | number;
  equipment?: Equipment;
  formValues?: UnmeteredEquipmentUsageDataForm | MeteredEquipmentUsageDataForm;
  updateFormValue?: (key: string, value: string) => void;
  quarterColumnWidth?: number;
  showQuarterColumn?: boolean;
  isCanada?: boolean;
};

export const UsagesDataTable: React.FC<UsagesDataTableProps> = observer((props) => {
  const {
    equipmentUsages,
    tableCells,
    cellWidth,
    equipment,
    formValues,
    updateFormValue,
    quarterColumnWidth = 8,
    showQuarterColumn = false,
    isCanada = false,
  } = props;

  return (
    <Box border={{ side: 'all', size: '0.4px', color: 'light-2' }}>
      <UsagesDataTableHeader
        rowLabels={tableCells.map((c) => c.label)}
        cellWidth={cellWidth}
        quarterColumnWidth={quarterColumnWidth}
        showQuarterColumn={showQuarterColumn}
      />

      {equipmentUsages.map((equipmentUsage, rowIndex) => (
        <UsagesDataTableRow
          key={rowIndex}
          equipment={equipment}
          equipmentUsage={equipmentUsage}
          rowIndex={rowIndex}
          tableCells={tableCells}
          cellWidth={cellWidth}
          formValues={formValues}
          updateFormValue={updateFormValue}
          isCanada={isCanada}
          quarterColumnWidth={quarterColumnWidth}
          showQuarterColumn={showQuarterColumn}
        />
      ))}
    </Box>
  );
});
