import { makeObservable, observable, runInAction } from 'mobx';
import {
  EquipmentUsageService,
  FuelPathwayService,
  TGetEquipmentUsageRequest,
  TListEquipmentUsagesInFacilityAndPeriodRequest,
  TListEquipmentUsagesRequest,
  TUpdateMeteredEquipmentUsageRequest,
  TUpdateMultipleMeteredEquipmentUsagesRequest,
  TUpdateMultipleUnmeteredEquipmentUsagesRequest,
  TUpdateUnmeteredEquipmentUsageRequest,
} from '/src/api';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import { EquipmentUsage, FuelPathway, TFuelPathwayId, TProgramId, TRegionId } from '/src/lib/models';
import { DateString, SelectOption, SelectOptions, TResponseMetadata } from '/src/lib/types';

export default class EquipmentUsageStore extends BaseStore {
  // Equipment Usages
  equipmentUsages?: EquipmentUsage[];
  equipmentUsagesRequest?: TListEquipmentUsagesRequest;
  equipmentUsagesMeta?: TResponseMetadata;

  facilityEquipmentUsages?: EquipmentUsage[];
  facilityEquipmentUsagesRequest?: TListEquipmentUsagesInFacilityAndPeriodRequest;
  facilityEquipmentUsagesMeta?: TResponseMetadata;

  // Fuel Pathways
  defaultFuelPathwayOptions: SelectOption<TFuelPathwayId>[] = [];
  fuelPathwayOptions: SelectOption<TFuelPathwayId>[] = [];
  fuelPathways?: FuelPathway[];

  limit = 20;
  currentPage = 1;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      limit: observable,
      currentPage: observable,
      equipmentUsages: observable,
      equipmentUsagesRequest: observable,
      equipmentUsagesMeta: observable,
      facilityEquipmentUsages: observable,
      facilityEquipmentUsagesRequest: observable,
      facilityEquipmentUsagesMeta: observable,
      defaultFuelPathwayOptions: observable,
      fuelPathwayOptions: observable,
      fuelPathways: observable,
    });
  }

  setLimit = (limit: number) => {
    runInAction(() => {
      this.limit = limit;
    });
  };

  setCurrentPage = (currentPage: number) => {
    runInAction(() => {
      this.currentPage = currentPage;
    });
  };

  setFuelPathwayOptions = (options: SelectOptions<TFuelPathwayId>) => {
    runInAction(() => {
      this.fuelPathwayOptions = options;
    });
  };

  setDefaultFuelPathwayOptions = (options: SelectOptions<TFuelPathwayId>) => {
    runInAction(() => {
      this.defaultFuelPathwayOptions = options;
    });
  };

  setFuelPathways = (fuelPathways?: FuelPathway[]) => {
    runInAction(() => {
      this.fuelPathways = fuelPathways;
    });
  };

  setEquipmentUsages = (equipmentUsages?: EquipmentUsage[]) => {
    runInAction(() => {
      this.equipmentUsages = equipmentUsages;
    });
  };

  setEquipmentUsagesRequest = (equipmentUsagesRequest?: TListEquipmentUsagesRequest) => {
    runInAction(() => {
      this.equipmentUsagesRequest = equipmentUsagesRequest;
    });
  };

  setEquipmentUsagesMeta = (equipmentUsagesMeta?: TResponseMetadata) => {
    runInAction(() => {
      this.equipmentUsagesMeta = equipmentUsagesMeta;
    });
  };

  setFacilityEquipmentUsages = (equipmentUsages?: EquipmentUsage[]) => {
    runInAction(() => {
      this.facilityEquipmentUsages = equipmentUsages;
    });
  };

  setFacilityEquipmentUsagesRequest = (equipmentUsagesRequest?: TListEquipmentUsagesInFacilityAndPeriodRequest) => {
    runInAction(() => {
      this.facilityEquipmentUsagesRequest = equipmentUsagesRequest;
    });
  };

  setFacilityEquipmentUsagesMeta = (equipmentUsagesMeta?: TResponseMetadata) => {
    runInAction(() => {
      this.facilityEquipmentUsagesMeta = equipmentUsagesMeta;
    });
  };

  fetchFuelPathways = async (
    programId: TProgramId,
    regionId: TRegionId,
    startReportingQuarter: DateString,
    endReportingQuarter: DateString
  ) => {
    const pathways = await FuelPathwayService.list({
      program_id: programId,
      region_id: regionId,
      start_reporting_quarter: startReportingQuarter,
      end_reporting_quarter: endReportingQuarter,
    });
    const pathwayOptions = pathways.map((p) => ({ label: p.code, value: p.id }));

    this.setFuelPathways(pathways);
    this.setFuelPathwayOptions(pathwayOptions);
    this.setDefaultFuelPathwayOptions(pathwayOptions);

    return pathways;
  };

  listEquipmentUsages = async (request: TListEquipmentUsagesRequest) => {
    if (!this.rootStore.userStore.user) return;

    const { meta, data } = await EquipmentUsageService.list(request);

    this.setFacilityEquipmentUsages(data);
    this.setFacilityEquipmentUsagesMeta(meta);

    return data;
  };

  getEquipmentUsage = async (request: TGetEquipmentUsageRequest) => {
    if (!this.rootStore.userStore.user) return;

    const equipmentUsage = await EquipmentUsageService.get(request);

    return equipmentUsage;
  };

  listEquipmentUsagesByFacility = async (request: TListEquipmentUsagesInFacilityAndPeriodRequest) => {
    if (!this.rootStore.userStore.user) return;

    const { meta, data } = await EquipmentUsageService.listByFacilityReportingPeriod(request);

    data.sort((a, b) =>
      (a.equipment_id ?? '') > (b.equipment_id ?? '') ? 1 : a.equipment_id === b.equipment_id ? 0 : -1
    );

    this.setFacilityEquipmentUsages(data);
    this.setFacilityEquipmentUsagesMeta(meta);

    return data;
  };

  /**
   * Update Multiple Metered Equipment Usages
   */
  updateMultipleMeteredEquipmentUsages = async (request: TUpdateMultipleMeteredEquipmentUsagesRequest) => {
    await EquipmentUsageService.updateMultipleMetered(request);
  };

  /**
   * Update Single Metered Equipment Usage
   */
  updateMeteredEquipmentUsage = async (request: TUpdateMeteredEquipmentUsageRequest) => {
    await EquipmentUsageService.updatedMetered(request);
  };

  /**
   * Update Multiple Unmetered Equipment Usages
   */
  updateMultipleUnmeteredEquipmentUsages = async (request: TUpdateMultipleUnmeteredEquipmentUsagesRequest) => {
    await EquipmentUsageService.updateMultipleUnmetered(request);
  };

  /**
   * Update Single Unmetered Equipment Usage
   */
  updateUnmeteredEquipmentUsage = async (request: TUpdateUnmeteredEquipmentUsageRequest) => {
    await EquipmentUsageService.updatedUnmetered(request);
  };
}
