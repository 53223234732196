import dayjs from 'dayjs';
import { Button, Form, Spinner, TextInput, Tip } from 'grommet';
import { Down, SettingsOption, Up } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiError, ClientReportingPeriodService } from '/src/api';
import {
  AddButton,
  Box,
  Card,
  CardBody,
  CardHeader,
  EquipmentUsageIcon,
  Link,
  ReportingPeriodDropdown,
  Text,
} from '/src/components';
import { useEquipmentStore, useEquipmentUsageStore, useGlobalStore, useUserStore } from '/src/context';
import {
  ClientReportingPeriod,
  EquipmentType,
  EquipmentUsage,
  Facility,
  TClientId,
  TClientReportingPeriodId,
  TEquipmentId,
  TEquipmentUsageId,
  TFacilityId,
} from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId, Float, FloatString, Integer } from '/src/lib/types';
import { formatNumber, getPeriodFromDateString } from '/src/utils';

export type MeteredEquipmentRowData = {
  facilityId: TFacilityId;
  equipmentId: TEquipmentId;
  clientId: TClientId;
  equipmentType?: EquipmentType;
  serialNumber: string;
  manufacturer: string;
  modelYear: number;
  modelNumber: string;
  unitNumber: string;
  currentUsageId: TEquipmentUsageId;
  previousUsageId?: TEquipmentUsageId;
  previousMeteredKwh?: string;
};

export const UpdateMultipleMeteredModal: React.FC<UpdateMultipleMeteredModalProps> = observer((props) => {
  const { facility, setIsVisible, setShouldRefresh } = props;

  /** Stores **/
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const equipmentStore = useEquipmentStore();
  const equipmentUsageStore = useEquipmentUsageStore();

  /** State **/
  const [isFetchingUsages, setIsFetchingUsages] = useState(true);
  const [isFetchingPeriods, setIsFetchingPeriods] = useState(true);
  const [_, setIsUpdating] = useState(false);
  const [allMeteredKwhValue, setAllMeteredKwhValue] = useState<FloatString>('');
  const [reportingPeriods, setReportingPeriods] = useState<ClientReportingPeriod[]>();
  const [selectedReportingPeriod, setSelectedReportingPeriod] = useState<ClientReportingPeriod>();
  const [sortColumn, setSortColumn] = useState('');
  const [sortAscending, setSortAscending] = useState(true);

  const [tableData, setTableData] = useState<MeteredEquipmentRowData[]>();
  const [formValues, setFormValues] = useState<Record<string, FloatString>>({});

  /** Computed **/
  const isLoading = isFetchingPeriods || isFetchingUsages;
  const hasReportingPeriods = !isLoading && !!reportingPeriods?.length;
  const hasData = !isLoading && !!tableData?.length;
  const isCanada = facility.address_region?.country_id === CountryId.Canada;
  const selectedIndex = reportingPeriods?.findIndex((p) => p.id === selectedReportingPeriod?.id) ?? -1;
  const previousPeriod = selectedIndex > -1 ? reportingPeriods?.[selectedIndex + 1] : undefined;
  const nextPeriod = selectedIndex > -1 ? reportingPeriods?.[selectedIndex - 1] : undefined;

  /** Methods **/
  const getIsEditable = (period?: ClientReportingPeriod) =>
    !period
      ? false
      : userStore.isExternalUser
      ? !period.is_finalized && !period.is_client_locked
      : !period.is_finalized;

  /**
   * Set kWh Value
   */
  const setValue = (usageId: TEquipmentUsageId, newValue?: FloatString) => {
    setFormValues({
      ...formValues,
      [usageId.toString()]: newValue ?? '',
    });
  };

  /**
   * Set All kWh Values
   */
  const setAllValues = (newValue?: FloatString) => {
    const value = newValue ?? '';

    const newKwhValues = formValues;

    for (const usageId in formValues) {
      newKwhValues[usageId.toString()] = value;
    }

    setFormValues(newKwhValues);
    setAllMeteredKwhValue(value);
  };

  /**
   * Fetch Reporting Periods
   */
  const fetchReportingPeriods = async (facility: Facility) => {
    if (!facility.client_id) return;

    try {
      setIsFetchingPeriods(true);

      const { data } = await ClientReportingPeriodService.list({
        client_id: facility.client_id,
        limit: 1000,
        is_finalized: 0,
        reporting_period_type_id: facility.reporting_period_type_id,
      });

      const periodData = data.reverse();

      setReportingPeriods(periodData);

      if (periodData.length) setSelectedReportingPeriod(periodData[0]);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listClientReportingPeriods.error);
    } finally {
      setIsFetchingPeriods(false);
    }
  };

  /**
   * Fetch EquipmentUsage Data
   */
  const fetchEquipmentUsages = async (
    facilityId: TFacilityId,
    selectedReportingPeriodId: TClientReportingPeriodId,
    previousPeriod?: ClientReportingPeriod
  ) => {
    try {
      setIsFetchingUsages(true);

      const promises = [
        equipmentUsageStore.listEquipmentUsagesByFacility({
          facility_id: facilityId,
          client_reporting_period_id: selectedReportingPeriodId,
          is_metered: true,
        }),
      ];
      if (previousPeriod) {
        promises.push(
          equipmentUsageStore.listEquipmentUsagesByFacility({
            facility_id: facilityId,
            client_reporting_period_id: previousPeriod.id,
            is_metered: true,
          })
        );
      }

      const [selectedUsages, previousUsages] = await Promise.all(promises);

      if (selectedUsages) {
        await populateTableData(selectedUsages, previousUsages);
      } else {
        console.warn('No selected usages found');
      }
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listClientReportingPeriods.error);
    } finally {
      setIsFetchingUsages(false);
    }
  };

  /**
   * Populates Table kWh Data
   */
  const populateTableData = async (
    reportingQuarterUsages: EquipmentUsage[],
    previousReportingPeriodUsages?: EquipmentUsage[]
  ) => {
    const dataRows: MeteredEquipmentRowData[] = [];
    const formValues: Record<string, FloatString> = {};

    for (const equipmentUsage of reportingQuarterUsages) {
      formValues[equipmentUsage.id] = equipmentUsage.total_kwh;

      const equipmentType = (
        equipmentStore.equipmentCategories ||
        (await equipmentStore.fetchEquipmentCategories()) ||
        []
      )
        .flatMap((category) => ({
          name: category.name,
          equipment_types: category.equipment_types?.filter(
            (type) => type.id === equipmentUsage.equipment?.equipment_type_id
          ),
        }))
        .find((category) => !!category.equipment_types?.length);

      const dataRow: MeteredEquipmentRowData = {
        facilityId: equipmentUsage.equipment?.facility_id ?? '-',
        equipmentId: equipmentUsage.equipment?.id ?? '-',
        clientId: equipmentUsage.equipment?.client_id ?? '-',
        equipmentType: equipmentType?.equipment_types?.[0],
        serialNumber: equipmentUsage.equipment?.serial_number ?? '',
        manufacturer: equipmentUsage.equipment?.manufacturer ?? '',
        modelYear: equipmentUsage.equipment?.model_year ?? '',
        modelNumber: equipmentUsage.equipment?.model_number ?? '',
        unitNumber: equipmentUsage.equipment?.unit_number ?? '',
        currentUsageId: equipmentUsage.id,
      };

      const previousUsage = previousReportingPeriodUsages?.find(
        (usage) => usage.equipment.id === equipmentUsage.equipment.id
      );

      if (previousUsage) {
        dataRow.previousUsageId = previousUsage.id;
        dataRow.previousMeteredKwh = previousUsage?.total_kwh;
      }

      dataRows.push(dataRow);
    }

    setTableData(dataRows);
    setFormValues(formValues);
    setAllMeteredKwhValue('');
  };

  /**
   * Update Metered Equipment
   */
  const updateEquipment = async () => {
    if (!facility || !selectedReportingPeriod) return;

    try {
      setIsUpdating(true);

      const equipmentUsages = Object.keys(formValues)
        .filter((usageId) => !!formValues[usageId])
        .map((usageId) => {
          return {
            equipment_usage_id: parseInt(usageId),
            total_kwh: parseFloat(formValues[usageId]),
          };
        });

      const updateRequest = {
        facility_id: facility.id,
        client_reporting_period_id: selectedReportingPeriod.id,
        equipment_usages: equipmentUsages as {
          equipment_usage_id: Integer;
          total_kwh: Float;
        }[],
      };

      await equipmentUsageStore.updateMultipleMeteredEquipmentUsages(updateRequest);
      toast.success(toastMessages.updateMultipleMeteredEquipment.success);
      setShouldRefresh(true);
      setIsVisible(false);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateMultipleMeteredEquipment.error);
    } finally {
      setIsUpdating(false);
    }
  };

  const sortByColumn = (columnName: keyof MeteredEquipmentRowData) => {
    let newSortAscending = sortAscending;

    if (sortColumn !== columnName) {
      setSortColumn(columnName);
      newSortAscending = true;
    } else {
      newSortAscending = !newSortAscending;
    }

    setSortAscending(newSortAscending);

    if (tableData) {
      const sortedData = tableData.sort((a, b) =>
        (
          newSortAscending
            ? (a[columnName] ?? '') > (b[columnName] ?? '')
            : (a[columnName] ?? '') < (b[columnName] ?? '')
        )
          ? 1
          : a[columnName] === b[columnName]
          ? 0
          : -1
      );
      setTableData(sortedData);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (facility.client_id) {
      fetchReportingPeriods(facility);
    }
  }, [facility.client_id]);

  useEffect(() => {
    if (facility && selectedReportingPeriod) {
      fetchEquipmentUsages(facility.id, selectedReportingPeriod.id, previousPeriod);
    }
  }, [facility, selectedReportingPeriod, previousPeriod]);

  /** Render **/
  const renderToolbar = (reportingPeriods: ClientReportingPeriod[]) => (
    <Box row justify="between" align="center" margin={{ horizontal: '1.5rem' }}>
      <Box gap="xsmall" justify="center">
        <Box row gap="xsmall">
          <Text size="xlarge" weight={500}>
            {getIsEditable(selectedReportingPeriod) ? 'Editing' : 'Viewing'} Period:
          </Text>
          <Text size="xlarge" weight={500} color="accent-1">
            {selectedReportingPeriod &&
              getPeriodFromDateString(selectedReportingPeriod.start_reporting_quarter, isCanada)}
          </Text>
        </Box>
        <Box>
          <Text size="medium" weight={300}>
            {dayjs(selectedReportingPeriod?.start_reporting_quarter).format('MMMM DD, YYYY')} -{' '}
            {dayjs(selectedReportingPeriod?.start_reporting_quarter)
              .add(2, 'months')
              .endOf('month')
              .format('MMMM DD, YYYY')}
          </Text>
        </Box>
      </Box>
      {!getIsEditable(selectedReportingPeriod) && (
        <Box justify="center" align="center" pad="1rem" gap="xsmall">
          <Text fontFamily="Lato, sans-serif">
            {selectedReportingPeriod?.is_client_locked
              ? 'This period has been locked and cannot be edited.'
              : 'This period has been finalized and can no longer be edited.'}
          </Text>
        </Box>
      )}
      <ReportingPeriodDropdown
        reportingPeriods={reportingPeriods}
        setSelectedReportingPeriod={setSelectedReportingPeriod}
        selectedReportingPeriod={selectedReportingPeriod}
        previousPeriod={previousPeriod}
        nextPeriod={nextPeriod}
        clientId={facility.client_id}
        isCanada={isCanada}
        showNav
      />
    </Box>
  );

  const renderDataHeader = () => (
    <Box row>
      <Box width="5%" border={{ size: '0.5px' }} />
      <Box
        width="19%"
        align="center"
        justify="center"
        border={{ size: '0.5px' }}
        onClick={() => sortByColumn('equipmentType')}
        gap="xsmall"
        row
      >
        <Text size="small" weight={300} toUpperCase>
          Charger Type
        </Text>
        {sortColumn === 'equipmentType' && <>{sortAscending ? <Up size="12px" /> : <Down size="12px" />}</>}
      </Box>
      <Box
        width="13%"
        align="center"
        justify="center"
        border={{ size: '0.5px' }}
        onClick={() => sortByColumn('serialNumber')}
        gap="xsmall"
        row
      >
        <Text size="small" weight={300} toUpperCase>
          Serial / VIN
        </Text>
        {sortColumn === 'serialNumber' && <>{sortAscending ? <Up size="12px" /> : <Down size="12px" />}</>}
      </Box>
      <Box
        width="13%"
        align="center"
        justify="center"
        border={{ size: '0.5px' }}
        onClick={() => sortByColumn('manufacturer')}
        gap="xsmall"
        row
      >
        <Text size="small" weight={300} toUpperCase>
          Manufacturer
        </Text>
        {sortColumn === 'manufacturer' && <>{sortAscending ? <Up size="12px" /> : <Down size="12px" />}</>}
      </Box>
      <Box
        width="10%"
        align="center"
        justify="center"
        border={{ size: '0.5px' }}
        onClick={() => sortByColumn('modelYear')}
        gap="xsmall"
        row
      >
        <Text size="small" weight={300} toUpperCase>
          Model Year
        </Text>
        {sortColumn === 'modelYear' && <>{sortAscending ? <Up size="12px" /> : <Down size="12px" />}</>}
      </Box>
      <Box
        width="13%"
        align="center"
        justify="center"
        border={{ size: '0.5px' }}
        onClick={() => sortByColumn('modelNumber')}
        gap="xsmall"
        row
      >
        <Text size="small" weight={300} toUpperCase>
          Model No.
        </Text>
        {sortColumn === 'modelNumber' && <>{sortAscending ? <Up size="12px" /> : <Down size="12px" />}</>}
      </Box>
      <Box
        width="13%"
        align="center"
        justify="center"
        border={{ size: '0.5px' }}
        onClick={() => sortByColumn('unitNumber')}
        gap="xsmall"
        row
      >
        <Text size="small" weight={300} toUpperCase>
          Unit No.
        </Text>
        {sortColumn === 'unitNumber' && <>{sortAscending ? <Up size="12px" /> : <Down size="12px" />}</>}
      </Box>
      <Box
        width="12%"
        align="center"
        justify="center"
        pad={{ vertical: 'xsmall' }}
        border={{ size: '0.5px' }}
        onClick={() => sortByColumn('previousMeteredKwh')}
      >
        <Box row gap="xsmall" align="center">
          <Box align="center">
            <Text size="small" weight={300} toUpperCase>
              Metered kWh
            </Text>
            {reportingPeriods && previousPeriod?.start_reporting_quarter && (
              <Text size="small" weight={300} toUpperCase>
                ({getPeriodFromDateString(previousPeriod?.start_reporting_quarter, isCanada)})
              </Text>
            )}
          </Box>
          {sortColumn === 'previousMeteredKwh' && <>{sortAscending ? <Up size="12px" /> : <Down size="12px" />}</>}
        </Box>
      </Box>
      <Box width="13%" align="center" justify="center" pad={{ vertical: 'xsmall' }} border={{ size: '0.5px' }}>
        <Text size="small" weight={300} toUpperCase>
          Metered kWh
        </Text>
        {selectedReportingPeriod?.start_reporting_quarter && (
          <Text size="small" weight={300} toUpperCase>
            ({getPeriodFromDateString(selectedReportingPeriod?.start_reporting_quarter, isCanada)})
          </Text>
        )}
      </Box>
    </Box>
  );

  const renderDataEditHeader = () => (
    <Box row>
      <Box width="5%" pad={{ vertical: 'small' }} border={{ size: '0.5px' }} />
      <Box width="19%" pad={{ vertical: 'small' }} border={{ size: '0.5px' }} />
      <Box width="13%" align="center" pad={{ vertical: 'small' }} border={{ size: '0.5px' }}></Box>
      <Box width="13%" align="center" pad={{ vertical: 'small' }} border={{ size: '0.5px' }}></Box>
      <Box width="10%" align="center" pad={{ vertical: 'small' }} border={{ size: '0.5px' }}></Box>
      <Box width="13%" align="center" pad={{ vertical: 'small' }} border={{ size: '0.5px' }}></Box>
      <Box width="13%" align="center" pad={{ vertical: 'small' }} border={{ size: '0.5px' }}></Box>
      <Box width="12%" pad={{ vertical: 'small' }} border={{ size: '0.5px' }} />
      <Box width="13%" border={{ size: '0.5px' }} justify="center">
        <Box row pad="small" gap="xsmall" align="center">
          <TextInput
            name="all_metered_kwh_value"
            value={allMeteredKwhValue}
            type="number"
            onChange={(e) => setAllValues(e.target.value)}
            style={{ background: 'white', paddingTop: '8px', paddingBottom: '8px' }}
            className="hide-input-arrows"
            disabled={!getIsEditable(selectedReportingPeriod)}
          />
          <Text size="small">kWh</Text>
        </Box>
      </Box>
    </Box>
  );

  const renderDataRow = (row: MeteredEquipmentRowData, index: Integer) => {
    return (
      <Box key={index} row background={index % 2 ? 'white' : 'light-6'}>
        <Box width="5%" align="center" justify="center" pad={{ vertical: 'small' }} border={{ size: '0.5px' }}>
          <Link to={`/clients/${row.clientId}/equipment/${row.equipmentId}`}>
            <Box align="center" justify="center">
              <Button plain icon={<SettingsOption />} tip={`View Equipment ID ${row.equipmentId}`} />
            </Box>
          </Link>
        </Box>
        <Box width="19%" align="center" justify="center" border={{ size: '0.5px' }}>
          <Text size="small" toUpperCase textAlign="center">
            {row.equipmentType?.name}
          </Text>
        </Box>
        <Box width="13%" align="center" justify="center" border={{ size: '0.5px' }}>
          <Text size="small" toUpperCase>
            <Tip content={row.serialNumber}>
              {row.serialNumber.length >= 22
                ? `${row.serialNumber.slice(0, 4)}...${row.serialNumber.slice(-4)}`
                : row.serialNumber}
            </Tip>
          </Text>
        </Box>
        <Box width="13%" align="center" justify="center" border={{ size: '0.5px' }}>
          <Text size="small" toUpperCase>
            {row.manufacturer}
          </Text>
        </Box>
        <Box width="10%" align="center" justify="center" border={{ size: '0.5px' }}>
          <Text size="small" toUpperCase>
            {row.modelYear}
          </Text>
        </Box>
        <Box width="13%" align="center" justify="center" border={{ size: '0.5px' }}>
          <Text size="small" toUpperCase>
            {row.modelNumber}
          </Text>
        </Box>
        <Box width="13%" align="center" justify="center" border={{ size: '0.5px' }}>
          <Text size="small" toUpperCase>
            {row.unitNumber}
          </Text>
        </Box>
        <Box width="12%" border={{ size: '0.5px' }} justify="center" align="center">
          {row.previousMeteredKwh ? (
            <Box row pad="small" gap="xsmall" align="center">
              <Text size="small" toUpperCase>
                {formatNumber(row.previousMeteredKwh, true)}
              </Text>
              <Text size="small">kWh</Text>
            </Box>
          ) : (
            <Box align="center">
              <Text size="small">-</Text>
            </Box>
          )}
        </Box>

        <Box width="13%" border={{ size: '0.5px' }} justify="center" align="center">
          <Box row pad="small" gap="xsmall" align="center">
            {!getIsEditable(selectedReportingPeriod) && (
              <Text size="small" toUpperCase>
                {formatNumber(formValues[row.currentUsageId], true)}
              </Text>
            )}
            {getIsEditable(selectedReportingPeriod) && (
              <TextInput
                name={row.currentUsageId?.toString()}
                value={formValues[row.currentUsageId]}
                type="number"
                onFocus={(e) => e.target.select()}
                onChange={(e) => setValue(row.currentUsageId, e.target.value)}
                style={{ background: 'white', paddingTop: '8px', paddingBottom: '8px' }}
                className="hide-input-arrows"
              />
            )}
            <Text size="small">kWh</Text>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderDataTable = () => (
    <Box border={{ size: '0.5px' }} margin={{ horizontal: '1.5rem' }}>
      {renderDataHeader()}
      {getIsEditable(selectedReportingPeriod) && renderDataEditHeader()}
      {tableData?.map(renderDataRow)}
    </Box>
  );

  return (
    <Card>
      <CardHeader title="Update Metered Equipment Usage" icon={<EquipmentUsageIcon />}>
        <AddButton label="CANCEL" onClick={() => setIsVisible(false)} />
        {hasReportingPeriods && getIsEditable(selectedReportingPeriod) && (
          <AddButton background="accent-1" color="white" label="SAVE CHANGES" onClick={() => updateEquipment()} />
        )}
      </CardHeader>
      <Form>
        <CardBody pad={isLoading || hasData ? { vertical: 'medium' } : 'none'} gap="medium">
          {hasReportingPeriods && renderToolbar(reportingPeriods)}
          {isLoading && (
            <Box pad={{ vertical: 'medium' }} height="10vh" fill="horizontal" justify="center" align="center">
              <Spinner size="large" />
            </Box>
          )}
          {!isLoading && (
            <>
              {hasData ? (
                renderDataTable()
              ) : (
                <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" align="center">
                  <Text size="medium" fontFamily="Lato, sans-serif">
                    {hasReportingPeriods
                      ? 'No metered equipment usage data was found for this quarter.'
                      : 'No usage data was found for this equipment.'}
                  </Text>
                </Box>
              )}
            </>
          )}
        </CardBody>
      </Form>
    </Card>
  );
});

export type UpdateMultipleMeteredModalProps = {
  facility: Facility;
  setIsVisible: (isVisible: boolean) => void;
  setShouldRefresh: (shouldRefresh: boolean) => void;
};
