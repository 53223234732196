import dayjs from 'dayjs';
import { Anchor, ButtonExtendedProps, Form } from 'grommet';
import { CircleInformation, Lock } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ApiError,
  ApiRequestOptions,
  ApiResult,
  ClientService,
  FacilityService,
  TUpdateFacilityRequest,
} from '/src/api';
import {
  Box,
  BreadcrumbNav,
  ContactsList,
  DeleteFacilityModal,
  EquipmentList,
  FormCard,
  FormCardSection,
  FormPage,
  InfoBox,
  Input,
  Line,
  RetireFacilityModal,
  Text,
  UtilitySelect,
} from '/src/components';
import { useClientStore, useFacilityStore, useGlobalStore, useUserStore } from '/src/context';
import { ClientReportingPeriod, Facility, RoleTypes, TFacilityId, User } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId, DateString, FacilityDataForm, FormMetadata, SelectOptions } from '/src/lib/types';
import { PageNotFound } from '/src/pages';
import { getFacilityLabel, getFormErrors, getQuarterFromDate, getQuarterFromDateString } from '/src/utils';

export const FacilityDetailsPage = observer(() => {
  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const facilityStore = useFacilityStore();
  const clientStore = useClientStore();

  /* Query Params */
  const params = useParams();
  const clientId = parseInt(params.client_id ?? '');
  const facilityId = parseInt(params.facility_id ?? '');
  const isInvalidParams = Number.isNaN(clientId) || Number.isNaN(facilityId);

  /** Refs **/
  const defaultFormData = useRef<FacilityDataForm>({
    name: '',
    address_line1: '',
    address_line2: '',
    address_city: '',
    address_region_id: null,
    address_post_code: '',
    utility_id: null,
    program_id: null,
    last_active_reporting_quarter: null,
  });

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [facility, setFacility] = useState<Facility | undefined>();
  const [clientName, setClientName] = useState('');
  const [facilityUsers, setFacilityUsers] = useState<User[]>([]);
  const [isCanada, setIsCanada] = useState(false);
  const [showRetireModal, setShowRetireModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isRetired, setIsRetired] = useState(false);
  const [reportingPeriodOptions, setReportingPeriodOptions] = useState<SelectOptions<DateString>>([]);
  const [menuButtonItems, setMenuButtonItems] = useState<ButtonExtendedProps[] | ButtonExtendedProps[][]>([]);

  const [formValues, setFormValues] = useState<FacilityDataForm>(defaultFormData.current);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /*** Computed **/
  const titleBar = isRetired ? (
    <InfoBox pad={{ horizontal: '0.5rem' }} gap="xsmall">
      <Lock size="16px" />
      <Text size="0.95rem" weight={600} toUpperCase>
        Retired
      </Text>
    </InfoBox>
  ) : undefined;

  const canRetireOrDelete = userStore.isClientAdmin
    ? !!userStore.user?.facilities.find((f) => f.id === facility?.id)
    : userStore.isFuseAdmin;

  const firstReportingPeriod = isCanada
    ? facility?.first_active_reporting_quarter?.slice(0, 4)
    : getQuarterFromDate(dayjs(facility?.first_active_reporting_quarter).toDate());

  const lastReportingPeriod = facility?.last_active_reporting_quarter
    ? isCanada
      ? facility.last_active_reporting_quarter?.slice(0, 4)
      : getQuarterFromDate(dayjs(facility.last_active_reporting_quarter).toDate())
    : undefined;

  /** Methods **/
  const updateFormValue = (key: string, value: any) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  const fetchPageData = async (facilityId: TFacilityId) => {
    try {
      const [facility, clientNames] = await Promise.all([
        facilityStore.fetchFacility(facilityId),
        ClientService.listClientNames(),
      ]);

      if (!facility) throw new ApiError({} as ApiRequestOptions, {} as ApiResult, 'Facility not found');

      setFacility(facility);
      setClientName(clientNames.find((client) => client.id === clientId)?.name ?? '');
      setIsCanada(facility.address_region?.country_id === CountryId.Canada);
      setFacilityUsers(
        facility.users
          .filter((u) => u.is_active && (u.role.id === RoleTypes.ClientAdmin || u.role.id === RoleTypes.FacilityAdmin))
          .sort((a, b) => b.role_id - a.role_id)
      );

      const isRetired =
        dayjs(facility?.last_active_reporting_quarter).isSame(new Date()) ||
        dayjs(facility?.last_active_reporting_quarter).isBefore(new Date());
      setIsRetired(isRetired);

      if (!isRetired) {
        const userCanRetire =
          userStore.isClientAdmin || userStore.isFacilityAdmin
            ? !!userStore.user?.facilities.find((f) => f.id === facility?.id)
            : userStore.isFuseAdmin;
        const userCanDelete = userStore.isFuseAdmin;

        setMenuButtonItems(
          facility.is_finalized && userCanRetire
            ? [{ label: 'Retire Facility', onClick: () => setShowRetireModal(true) }]
            : userCanDelete
            ? [{ label: 'Delete Facility', onClick: () => setShowDeleteModal(true) }]
            : []
        );
      }

      if (facility.client_id) {
        const reportingPeriods = (
          await clientStore.fetchClientReportingPeriods(facility.client_id, facility.reporting_period_type_id, true)
        ).sort((a, b) => a.id - b.id);

        const results: ClientReportingPeriod[] = [];

        const lastFinalized = reportingPeriods.find(
          (p, i) => p.is_finalized && reportingPeriods[i + 1]?.is_finalized === false
        );
        const firstUnfinalized = reportingPeriods.find((p) => !p.is_finalized);

        if (firstUnfinalized) results.push(firstUnfinalized);
        if (lastFinalized) results.push(lastFinalized);

        setReportingPeriodOptions(
          results.map((p) => ({
            label: getQuarterFromDateString(p.start_reporting_quarter),
            value: p.start_reporting_quarter,
          })) as SelectOptions<DateString>
        );
      }

      setFormValues({
        ...formValues,
        name: facility.name ?? '',
        address_line1: facility.address_line1,
        address_line2: facility.address_line2 ?? '',
        address_city: facility.address_city,
        address_region: facility.address_region,
        address_post_code: facility.address_post_code,
        utility_id: facility.utility?.id,
        last_active_reporting_quarter: facility.last_active_reporting_quarter,
      });
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchFacility.error);
    }
  };

  const validateForm = () => {
    const formFields: FormMetadata = {
      name: { label: 'Name', max: 50 },
      address_line1: { label: 'Address', max: 100, required: true },
      address_line2: { label: 'Address', max: 100 },
      address_city: { label: 'City', max: 50, required: true },
      address_post_code: { label: 'Postal code', max: 20, required: true },
      utility_id: { label: 'Utility', required: !isCanada },
    };

    const errors = getFormErrors(formFields, formValues);
    if (errors) setFormErrors(errors);
    return !errors;
  };

  const updateFacility = async () => {
    if (!facilityId || !clientId || !validateForm()) return;

    const updatedFacility: TUpdateFacilityRequest = {
      id: facilityId,
      name: formValues.name || null,
      address_line1: formValues.address_line1,
      address_line2: formValues.address_line2 || null,
      address_city: formValues.address_city,
      address_post_code: formValues.address_post_code,
      utility_id: isCanada ? null : formValues.utility_id,
      last_active_reporting_quarter: formValues.last_active_reporting_quarter || null,
    };

    try {
      setIsUpdating(true);
      await FacilityService.update(updatedFacility);
      toast.success(toastMessages.updateFacility.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateFacility.error);
    } finally {
      setIsUpdating(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (facilityId) {
      setIsLoading(true);
      fetchPageData(facilityId).finally(() => setIsLoading(false));
    }
  }, [facilityId]);

  useEffect(() => {
    if (facilityId && shouldRefresh) {
      fetchPageData(facilityId).finally(() => setShouldRefresh(false));
    }
  }, [facilityId, shouldRefresh]);

  /** Render **/
  return isInvalidParams ? (
    <PageNotFound />
  ) : (
    <FormPage
      title={getFacilityLabel(facility)}
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
            { name: clientName, link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
            { name: 'Facilities', link: `/clients/${clientId}/facilities` },
          ]}
          currentPageName={getFacilityLabel(facility)}
        />
      }
      titleBar={titleBar}
      isLoading={isLoading}
      isDetailPage
      hideMenuButton={!isLoading && facility && !menuButtonItems.length}
      menuButtonItems={menuButtonItems}
    >
      <FormCard
        title="General Information"
        icon={<CircleInformation size="24px" color="brand" />}
        onSubmit={updateFacility}
        isLoading={isLoading || isUpdating}
      >
        <Form>
          <Box direction="row" gap="1rem">
            <FormCardSection title="Facility Information" width="55%">
              <Box direction="row" gap="1rem">
                <Input
                  id="facility_name"
                  label="Name"
                  width={lastReportingPeriod ? '50%' : '75%'}
                  value={formValues.name ?? ''}
                  setValue={(value) => updateFormValue('name', value)}
                  error={formErrors['name']}
                  onSubmit={updateFacility}
                />
                <Box width={lastReportingPeriod ? '50%' : '25%'} row gap="1rem">
                  <Input
                    id="first_active_reporting_quarter"
                    label="First Reporting Period"
                    value={firstReportingPeriod}
                    setValue={() => {}}
                    required
                    disabled
                  />
                  {!!lastReportingPeriod && (
                    <Input
                      id="last_active_reporting_quarter"
                      label="Last Reporting Period"
                      placeholder="Still Active"
                      value={lastReportingPeriod}
                      setValue={() => {}}
                      required
                      disabled
                    />
                  )}
                </Box>
              </Box>
              <Input
                id="address_line1"
                autoComplete="address_line1"
                label="Address"
                value={formValues.address_line1}
                setValue={(value) => updateFormValue('address_line1', value)}
                error={formErrors['address_line1']}
                onSubmit={updateFacility}
                required
                disabled={facility?.is_finalized}
              />
              <Input
                id="address_line2"
                autoComplete="address_line2"
                label="Address (continued)"
                value={formValues.address_line2 ?? ''}
                setValue={(value) => updateFormValue('address_line2', value)}
                error={formErrors['address_line2']}
                onSubmit={updateFacility}
                disabled={facility?.is_finalized}
              />
              <Box direction="row" gap="1rem">
                <Input
                  id="address_city"
                  label="City"
                  value={formValues.address_city}
                  setValue={(value) => updateFormValue('address_city', value)}
                  error={formErrors['address_city']}
                  onSubmit={updateFacility}
                  width="50%"
                  required
                  disabled={facility?.is_finalized}
                />
                <Input
                  id="address_region.short_code"
                  label="State / Province"
                  value={formValues.address_region?.short_code ?? ''}
                  setValue={() => {}}
                  required
                  disabled
                />
                <Input
                  id="address_post_code"
                  label="Postal Code"
                  value={formValues.address_post_code}
                  setValue={(value) => updateFormValue('address_post_code', value)}
                  error={formErrors['address_post_code']}
                  onSubmit={updateFacility}
                  required
                  disabled={facility?.is_finalized}
                />
              </Box>
              <Line margin="0.5rem" />
              <Box row gap="1rem">
                <Input
                  id="program_id"
                  label="Program"
                  value={facility?.program?.name}
                  setValue={() => {}}
                  required
                  disabled
                  fill="horizontal"
                />
                {!isCanada && (
                  <UtilitySelect
                    id="utility"
                    label="FSE Utility"
                    placeholder="Choose..."
                    emptySearchMessage={
                      !formValues.address_region?.id
                        ? `Choose a state to see available Utilities.`
                        : 'No Utilities found.'
                    }
                    value={formValues.utility_id}
                    setValue={(value) => updateFormValue('utility_id', value)}
                    error={formErrors['utility_id']}
                    regionId={formValues.address_region?.id}
                    required={!isCanada}
                    fill="horizontal"
                  />
                )}
              </Box>
            </FormCardSection>
            <Line direction="vertical" margin="3rem" />
            <FormCardSection width="40%">
              <ContactsList contacts={facilityUsers} isFacility isUpdatePage />
            </FormCardSection>
          </Box>
        </Form>
      </FormCard>
      <Anchor id="equipment" />
      <EquipmentList
        id="equipment_list"
        title="Fleet List"
        clientId={clientId}
        facilityId={facilityId}
        isCanada={isCanada}
        firstReportingQuarter={facility?.first_active_reporting_quarter}
        lastReportingQuarter={facility?.last_active_reporting_quarter}
        showBulkUpdateButton
        showAddButton
      />
      {showRetireModal && !!facility && (
        <RetireFacilityModal
          facility={facility}
          setIsVisible={setShowRetireModal}
          setShouldRefresh={setShouldRefresh}
          reportingPeriodOptions={reportingPeriodOptions}
        />
      )}
      {showDeleteModal && !!facility && (
        <DeleteFacilityModal
          facility={facility}
          setIsVisible={setShowDeleteModal}
          setShouldRefresh={setShouldRefresh}
        />
      )}
    </FormPage>
  );
});

export default FacilityDetailsPage;
