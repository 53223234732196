import { TListResponse } from '.';
import {
  EquipmentUsage,
  TClientReportingPeriodId,
  TEquipmentId,
  TEquipmentUsageId,
  TFacilityId,
  TFuelPathwayId,
} from '/src/lib/models';
import { Float, Integer, Nullable } from '/src/lib/types';

/**
 * List Equipment Usages
 */
export type TListEquipmentUsagesRequest = {
  equipment_id: TEquipmentId;
  page?: Integer;
  limit?: Integer;
  client_reporting_period_id?: TClientReportingPeriodId;
};

export type TListEquipmentUsagesResponse = TListResponse<EquipmentUsage[]>;

/**
 * List Equipment Usages in Facility and Reporting Period
 */
export type TListEquipmentUsagesInFacilityAndPeriodRequest = {
  facility_id: TFacilityId;
  client_reporting_period_id: TClientReportingPeriodId;
  page?: Integer;
  limit?: Integer;
  is_metered?: boolean;
};

export type TListEquipmentUsagesInFacilityAndPeriodResponse = TListResponse<EquipmentUsage[]>;

/**
 * Get Equipment Usage
 */
export type TGetEquipmentUsageRequest = {
  id: TEquipmentUsageId;
};

export type TGetEquipmentUsageResponse = EquipmentUsage;

/**
 * Update - Metered Equipment Usage
 *
 * - Use this endpoint when `is_metered` is true
 * - Only Admins can update when `is_finalized` is true
 */
export type TUpdateMeteredEquipmentUsageRequest = {
  equipment_usage_id: TEquipmentUsageId;
  fuel_pathway_id: TFuelPathwayId;
  is_book_and_claim_applied: Nullable<boolean>;
  total_kwh: Float; // max 2 decimals
};

/**
 * Update - Multiple Metered Equipment Usages
 *
 * - Use this endpoint when `is_metered` is true
 * - Only Admins can update when `is_finalized` is true
 * - All the given Equipment Usage IDs must be for metered equipment in the given facility in the given reporting period
 */
export type TUpdateMultipleMeteredEquipmentUsagesRequest = {
  facility_id: TFacilityId;
  client_reporting_period_id: TClientReportingPeriodId;
  equipment_usages: {
    equipment_usage_id: TEquipmentUsageId;
    total_kwh: Float; // max 2 decimals
  }[];
};

/**
 * Update - Unmetered Equipment Usage
 *
 * - Use this endpoint when `is_metered` is false
 * - Only Admins can update when `is_finalized` is true
 */
export type TUpdateUnmeteredEquipmentUsageRequest = {
  equipment_usage_id: TEquipmentUsageId;
  fuel_pathway_id: TFuelPathwayId;
  is_book_and_claim_applied: boolean;
  is_paused: boolean;
  battery_capacity_rating_ah: Float;
  voltage: Float;
  charge_cycles_per_shift: Float; // max 2 decimals
  shifts_per_day: Float; // max 2 decimals
  work_days_per_quarter: Float; // max 2 decimals
  percent_charge_return_factor: Integer;
  percent_charger_efficiency_rating: Integer;
  percent_depth_of_discharge: Integer;
};

/**
 * Update - Multiple Unmetered Equipment Usages
 *
 * - Use this endpoint when `is_metered` is false
 * - Only Admins can update when `is_finalized` is true
 * - All the unmetered equipment in the given facility and reporting period will have their values changed
 */
export type TUpdateMultipleUnmeteredEquipmentUsagesRequest = {
  client_reporting_period_id: TClientReportingPeriodId;
  facility_id: TFacilityId;
  charge_cycles_per_shift: Float; // max 2 decimals
  shifts_per_day: Float; // max 2 decimals
  work_days_per_quarter: Float; // max 2 decimals
  percent_charge_return_factor: Integer;
  percent_charger_efficiency_rating: Integer;
  percent_depth_of_discharge: Integer;
};
