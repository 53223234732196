import type {
  CancelablePromise,
  TCreateEquipmentExternalRequest,
  TCreateEquipmentExternalResponse,
  TCreateEquipmentInternalRequest,
  TCreateEquipmentInternalResponse,
  TDeleteEquipmentRequest,
  TGetEquipmentRequest,
  TGetEquipmentResponse,
  TListAllEquipmentRequest,
  TListAllEquipmentResponse,
  TListClientEquipmentRequest,
  TListClientEquipmentResponse,
  TListEquipmentRequest,
  TListEquipmentResponse,
  TRetireEquipmentRequest,
  TUpdateFinalizedEquipmentInternalRequest,
  TUpdateFinalizedEquipmentInternalResponse,
  TUpdateNonFinalizedEquipmentInternalRequest,
  TUpdateNonFinalizedEquipmentInternalResponse,
  TUpdateNonPendingEquipmentExternalRequest,
  TUpdateNonPendingEquipmentExternalResponse,
  TUpdatePendingEquipmentExternalRequest,
  TUpdatePendingEquipmentExternalResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class EquipmentService {
  /**
   * List Equipment by Facility
   * @returns Equipment[] Equipment[]
   * @throws ApiError
   */
  public static list({ facility_id, ...req }: TListEquipmentRequest): CancelablePromise<TListEquipmentResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/facilities/{facility_id}/equipment',
      path: { facility_id },
      query: req,
    });
  }
  /**
   * List Equipment by Client
   * @returns Equipment[] Equipment[]
   * @throws ApiError
   */
  public static listByClient({
    client_id,
    ...req
  }: TListClientEquipmentRequest): CancelablePromise<TListClientEquipmentResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{client_id}/equipment',
      path: { client_id },
      query: req,
    });
  }

  /**
   * List All Equipment
   * @returns Equipment[] Equipment[]
   * @throws ApiError
   */
  public static listAll(req?: TListAllEquipmentRequest): CancelablePromise<TListAllEquipmentResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/equipment',
      query: req,
    });
  }

  /**
   * Get Equipment
   * @returns Equipment Equipment
   * @throws ApiError
   */
  public static get({ id }: TGetEquipmentRequest): CancelablePromise<TGetEquipmentResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/equipment/{id}',
      path: { id },
    });
  }

  /**
   * Create Equipment - Internal Users
   * @param equipment Equipment
   * @returns Equipment Equipment
   * @throws ApiError
   */
  public static createInternal({
    facility_id,
    ...req
  }: TCreateEquipmentInternalRequest): CancelablePromise<TCreateEquipmentInternalResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/facilities/{facility_id}/equipment/internalUser',
      path: { facility_id },
      body: req,
    });
  }

  /**
   * Create Equipment - External Users
   * @param equipment Equipment
   * @returns Equipment Equipment
   * @throws ApiError
   */
  public static createExternal({
    facility_id,
    ...req
  }: TCreateEquipmentExternalRequest): CancelablePromise<TCreateEquipmentExternalResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/facilities/{facility_id}/equipment/externalUser',
      path: { facility_id },
      body: req,
    });
  }

  /**
   * Update NonFinalized Equipment - Internal Users
   * @param equipment Equipment
   * @returns Equipment Equipment
   * @throws ApiError
   */
  public static updateNonFinalizedInternal({
    id,
    ...req
  }: TUpdateNonFinalizedEquipmentInternalRequest): CancelablePromise<TUpdateNonFinalizedEquipmentInternalResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/equipment/{id}/internalUser/notFinalized',
      path: { id },
      body: req,
    });
  }

  /**
   * Update Finalized Equipment - Internal Users
   * @param equipment Equipment
   * @returns Equipment Equipment
   * @throws ApiError
   */
  public static updateFinalizedInternal({
    id,
    ...req
  }: TUpdateFinalizedEquipmentInternalRequest): CancelablePromise<TUpdateFinalizedEquipmentInternalResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/equipment/{id}/internalUser/finalized',
      path: { id },
      body: req,
    });
  }

  /**
   * Update Pending Equipment - External Users
   * @param equipment Equipment
   * @returns Equipment Equipment
   * @throws ApiError
   */
  public static updatePendingExternal({
    id,
    ...req
  }: TUpdatePendingEquipmentExternalRequest): CancelablePromise<TUpdatePendingEquipmentExternalResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/equipment/{id}/externalUser/pending',
      path: { id },
      body: req,
    });
  }

  /**
   * Update Non-Pending Equipment - External Users
   * @param equipment Equipment
   * @returns Equipment Equipment
   * @throws ApiError
   */
  public static updateNonPendingExternal({
    id,
    ...req
  }: TUpdateNonPendingEquipmentExternalRequest): CancelablePromise<TUpdateNonPendingEquipmentExternalResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/equipment/{id}/externalUser/notPending',
      path: { id },
      body: req,
    });
  }

  /**
   * Retire Equipment
   * @throws ApiError
   */
  public static retire({ id, ...req }: TRetireEquipmentRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/equipment/{id}/retire',
      path: { id },
      body: req,
    });
  }

  /**
   * Delete Equipment
   * @throws ApiError
   */
  public static delete({ id }: TDeleteEquipmentRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/equipment/{id}',
      path: { id },
    });
  }
}
