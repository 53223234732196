import { observer } from 'mobx-react-lite';
import React from 'react';
import { Box, Text } from '/src/components';

export type UsagesDataTableHeaderProps = {
  rowLabels: string[];
  cellWidth?: string | number;
  quarterColumnWidth?: number;
  showQuarterColumn?: boolean;
};

export const UsagesDataTableHeader: React.FC<UsagesDataTableHeaderProps> = observer((props) => {
  const { rowLabels, cellWidth, quarterColumnWidth, showQuarterColumn = false } = props;

  const leftoverWidth = 100 - (quarterColumnWidth ?? 0);
  const width = cellWidth ?? (showQuarterColumn && quarterColumnWidth ? `${leftoverWidth / 6}%` : `${100 / 6}%`);

  return (
    <Box row>
      <Box
        width="3rem"
        style={{ minWidth: '3rem' }}
        align="center"
        border={{ side: 'all', size: '0.4px', color: 'light-2' }}
        pad="small"
      />
      {showQuarterColumn && quarterColumnWidth && (
        <Box
          width={`${quarterColumnWidth}%`}
          style={{ minWidth: '3rem' }}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Text size="small" weight={300} toUpperCase>
            Period
          </Text>
        </Box>
      )}
      {rowLabels.map((label) => (
        <Box
          key={label}
          width={typeof width === 'number' ? width.toString() : width}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Text size="small" weight={300} toUpperCase>
            {label}
          </Text>
        </Box>
      ))}
    </Box>
  );
});
