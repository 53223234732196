import { Box, BoxProps, ResponsiveContext, Text } from 'grommet';
import { useContext } from 'react';
import { ExternalLink, FuseLogo } from '/src/components';
import { config } from '/src/config';
import { pxToRem } from '/src/utils';

export const FooterNav: React.FC<FooterNavProps> = (props) => {
  const screenSize = useContext(ResponsiveContext);
  const isMobile = screenSize === ('small' || 'xsmall');

  const { flex, height, ...boxProps } = props;

  return (
    <Box
      as="footer"
      height={height ?? pxToRem(110)}
      direction={isMobile ? 'column' : 'row'}
      flex={flex ?? 'grow'}
      background="transparent"
      justify={isMobile ? undefined : 'between'}
      align={isMobile ? 'center' : undefined}
      gap={isMobile ? '1rem' : undefined}
      pad={{
        vertical: '2rem',
        horizontal: '8rem',
      }}
      border={{ color: 'light-2', side: 'top', size: 'small' }}
      {...boxProps}
    >
      <Box align={isMobile ? 'center' : undefined} gap="1rem">
        <Text size="0.875rem" color="dark-2">
          © {new Date().getFullYear()} FuSE. All Rights Reserved.
        </Text>
        <Box direction="row">
          <Box alignSelf="center" height="0.8125rem" justify="center">
            <ExternalLink to={config.urls.privacyPolicyUrl}>
              <Text size="0.8125rem" color="accent-4">
                Privacy Policy
              </Text>
            </ExternalLink>
          </Box>
        </Box>
      </Box>
      <ExternalLink to={config.urls.fuseWebsiteUrl}>
        <FuseLogo height="40px" />
      </ExternalLink>
    </Box>
  );
};

export type FooterNavProps = BoxProps;
