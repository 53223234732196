import { TokenType, User } from '.';

export type TTokenId = number;

export interface Token {
  id: TTokenId;
  user: User;
  token_type: TokenType;
  token_hash: string;
}
