import { Button } from 'grommet';
import { Trash } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { ApiError, ProgramService } from '/src/api';
import {
  AddButton,
  Box,
  Card,
  CardBody,
  CardHeader,
  CommissionIcon,
  FormCardSection,
  LoadingSpinner,
  MaskedInput,
  ProgramSelect,
  Select,
} from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Program, ProgramCommission, Region, TProgramId, TRegionId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { FormErrors, ProgramCommissionDataForm, SelectOptions } from '/src/lib/types';
import { getIsCanada } from '/src/utils';

export type CommissionListProps = {
  regionId?: TRegionId;
  id?: string;
};

export const CommissionList: React.FC<CommissionListProps> = observer((props) => {
  const { regionId, id } = props;

  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /** Refs **/
  const defaultFormValues = useRef<ProgramCommissionDataForm>({
    program_id: NaN,
    is_percent_rate: false,
    rate: '',
  });

  /** State **/
  // const [isLoadingPrograms, setIsLoadingPrograms] = useState(true);
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [programOptions, setProgramOptions] = useState<SelectOptions<TProgramId>>();
  const [defaultProgramOptions, setDefaultProgramOptions] = useState<SelectOptions<TProgramId>>();
  const [commissions, setCommissions] = useState<ProgramCommission[]>([]);

  const [formValues, setFormValues] = useState<ProgramCommissionDataForm[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  /** Computed **/
  const isLoading = isLoadingPrograms;
  const isCanada = getIsCanada({} as Region);
  const hasCommissions = !!commissions?.length;

  /** Methods **/

  /**
   * Update form data
   * @param key
   * @param value
   */
  const updateFormValue = (key: string, index: number, value: any) => {
    const updatedValues = [...formValues];
    updatedValues[index] ??= defaultFormValues.current;
    updatedValues[index][key] = value;
    setFormValues(updatedValues);
    if (formErrors[key]) formErrors[key] = '';
  };

  const handleAddCommission = () => {
    setFormValues([...formValues, { ...defaultFormValues.current }]);
  };

  const handleRemoveCommission = (index: number) => {
    const updatedValues = formValues.filter((v, i) => i !== index);
    setFormValues(updatedValues);
  };

  const fetchPrograms = async (region_id: TRegionId) => {
    if (!userStore.user) return;

    try {
      setIsLoadingPrograms(true);

      const programs = await ProgramService.list({ region_id });
      setPrograms(programs);

      let options = programs.map((program) => ({
        label: program.name,
        value: program.id,
      }));

      if (formValues.length) {
        const existingProgramIds = formValues.map((v) => v.program_id);
        options = options.filter(({ value }) => !existingProgramIds.includes(value));
      }

      setProgramOptions(options);
      setDefaultProgramOptions(options);

      // if (options.length === 1) setValue(options[0].value);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listPrograms.error);
    } finally {
      setIsLoadingPrograms(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (regionId) {
      fetchPrograms(regionId);
    }
  }, [regionId]);

  /** Render **/

  return (
    <Card id={id}>
      <CardHeader title="Program Commissions" icon={<CommissionIcon />}>
        <AddButton
          background="accent-1"
          color="white"
          // style={{ opacity: isLoading || isUpdatingCurrent || !isEditable ? 0.5 : 1 }}
          label={'Add Commission'}
          tip={`Add Program Commissions`}
          onClick={() => handleAddCommission()}
        />
      </CardHeader>
      <CardBody>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <FormCardSection>
            {formValues.map((commission, index) => (
              <Box row gap="1rem">
                <ProgramSelect
                  id="program_id"
                  label="Program"
                  placeholder="Choose..."
                  emptySearchMessage={`Choose a ${isCanada ? 'province' : 'state'} to see available Programs.`}
                  value={commission.program_id}
                  setValue={(value) => updateFormValue('program_id', index, value)}
                  error={formErrors['program_id']}
                  regionId={regionId}
                  required
                  fill="horizontal"
                  options={programOptions}
                />
                <Select
                  id="is_percent_rate"
                  label="Commission Type"
                  value={commission.is_percent_rate ? 'Percentage' : 'Fixed'}
                  setValue={(value) => updateFormValue('is_percent_rate', index, value === 'Percentage')}
                  options={['Fixed', 'Percentage']}
                  error={formErrors['is_percent_rate']}
                  required
                  fill="horizontal"
                />
                <MaskedInput
                  id="value"
                  label="Commission Rate"
                  value={commission.rate}
                  setValue={(value) => updateFormValue('rate', index, value)}
                  error={formErrors['rate']}
                  componentType={commission.is_percent_rate ? 'percent' : 'number'}
                  type="number"
                  required
                  fill="horizontal"
                />
                <Button
                  plain
                  icon={<Trash size="20px" color="red" />}
                  onClick={() => handleRemoveCommission(index)}
                  tip="Remove Commission"
                />
              </Box>
            ))}
          </FormCardSection>
        )}
      </CardBody>
    </Card>
  );
});
