import { Tip } from 'grommet';
import { SettingsOption } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';
import { Box, Link, Text, UsagesDataTableCell } from '/src/components';
import { Equipment, EquipmentUsage } from '/src/lib/models';
import { DataTableCell, MeteredEquipmentUsageDataForm, UnmeteredEquipmentUsageDataForm } from '/src/lib/types';
import { getPeriodFromDateString } from '/src/utils';

export type UsagesDataTableRowProps = PropsWithChildren & {
  equipmentUsage: EquipmentUsage;
  tableCells: DataTableCell[];
  cellWidth?: string | number;
  rowIndex?: number;
  quarterColumnWidth?: number;
  equipment?: Equipment;
  isCanada?: boolean;
  showQuarterColumn?: boolean;
  formValues?: UnmeteredEquipmentUsageDataForm | MeteredEquipmentUsageDataForm;
  updateFormValue?: (key: string, value: string) => void;
};

export const UsagesDataTableRow: React.FC<UsagesDataTableRowProps> = observer(({ children, ...props }) => {
  const {
    equipmentUsage,
    tableCells,
    cellWidth,
    rowIndex,
    quarterColumnWidth,
    equipment,
    isCanada = false,
    showQuarterColumn = false,
    formValues,
    updateFormValue,
  } = props;

  const leftoverWidth = 100 - (quarterColumnWidth ?? 0);
  const width = cellWidth ?? (showQuarterColumn && quarterColumnWidth ? `${leftoverWidth / 6}%` : `${100 / 6}%`);

  const getIsDisabled = (key: string) => key === 'work_days_per_quarter' && equipmentUsage.is_paused;

  return (
    <Box key={rowIndex} row background={rowIndex && rowIndex % 2 ? 'white' : 'light-6'}>
      <Box
        width="3rem"
        style={{ minWidth: '3rem' }}
        justify="center"
        align="center"
        border={{ side: 'all', size: '0.4px', color: 'light-2' }}
        pad="small"
      >
        <Link to={`/clients/${equipment?.client_id}/equipment/${equipment?.id}/usages/${equipmentUsage.id}`}>
          <Box align="center" justify="center">
            <Tip
              content={`View ${getPeriodFromDateString(
                equipmentUsage.start_reporting_quarter,
                isCanada
              )} Usage Details`}
            >
              <SettingsOption size="20px" color="accent-1" />
            </Tip>
          </Box>
        </Link>
      </Box>
      {showQuarterColumn && (
        <Box
          width={`${quarterColumnWidth}%`}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Text toUpperCase weight={500}>
            {getPeriodFromDateString(equipmentUsage.start_reporting_quarter, isCanada)}
          </Text>
        </Box>
      )}
      {tableCells.map((cellData, cellIndex) => (
        <UsagesDataTableCell
          index={cellIndex}
          key={cellIndex}
          width={width}
          isEditable={cellData.isEditable}
          valueKey={cellData.valueKey}
          formValues={formValues}
          updateFormValue={updateFormValue}
          unit={cellData.unit}
          inputMode={cellData.isEditable ? cellData.inputMode : undefined}
          formatNumber={cellData.formatNumber}
        />
      ))}
    </Box>
  );
});
