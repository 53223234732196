import { DataTable, DropButton, Menu, Pagination, Tip } from 'grommet';
import { Dashboard, Filter, Menu as MenuIcon, SettingsOption } from 'grommet-icons';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiError, EquipmentService, TListEquipmentRequest } from '/src/api';
import {
  AddEquipmentModal,
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  DataTableHeader,
  DataTableItem,
  EquipmentIcon,
  Filters,
  FiltersDropdown,
  Link,
  LoadingSpinner,
  SearchInput,
  Text,
  UpdateMultipleMeteredModal,
  UpdateMultipleUnmeteredModal,
} from '/src/components';
import { useFacilityStore, useGlobalStore, useUserStore } from '/src/context';
import { Equipment, TEquipmentId, TFacilityId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { DateString, EntityStatus, Nullable, TResponseMetadata } from '/src/lib/types';
import { formatNumber, getPeriodFromDateString, pxToRem } from '/src/utils';
import dayjs from 'dayjs';

export const EquipmentList: React.FC<EquipmentListProps> = (props) => {
  const {
    title,
    clientId,
    facilityId,
    firstReportingQuarter,
    lastReportingQuarter,
    currentReportingQuarter,
    isCanada,
    showAddButton,
    showBulkUpdateButton,
    showFilters,
    showSearchInput,
    isLoading: propIsLoading,
    setIsLoading: propSetIsLoading,
    equipment: propEquipment,
    fetchEquipment: propFetchEquipment,
    currentPage: propCurrentPage,
    setCurrentPage: propSetCurrentPage,
    metadata: propMetadata,
    hideHeader,
    ...boxProps
  } = props;

  /** Context **/
  const navigate = useNavigate();
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const facilityStore = useFacilityStore();

  /** Refs **/
  const defaultFilters = useRef<Record<string, string | undefined>>({
    is_active: 'true',
    is_metered: undefined,
  });

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [showEquipmentModal, setShowEquipmentModal] = useState(false);
  const [showEditUnmetered, setShowEditUnmetered] = useState(false);
  const [showEditMetered, setShowEditMetered] = useState(false);
  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState(false);
  const [isFiltersMenuHovered, setIsFiltersMenuHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState('No equipment found.');

  const [equipment, setEquipment] = useState<Equipment[]>();
  const [metadata, setEquipmentMetadata] = useState<TResponseMetadata>();
  const [request, setRequest] = useState<TListEquipmentRequest>({
    facility_id: facilityId,
  });
  const [filters, setFilters] = useState<Record<string, string | undefined>>(defaultFilters.current);

  /** Computed **/
  const _currentPage = propCurrentPage ?? currentPage;
  const _isLoading = propIsLoading ?? isLoading;
  const _equipment = propEquipment ?? equipment;
  const _metadata = propMetadata ?? metadata;
  const hasEquipment = !!_equipment?.length;
  const _setIsLoading = propSetIsLoading ?? setIsLoading;
  const _setCurrentPage = propSetCurrentPage ?? setCurrentPage;

  /** Methods **/
  const fetchEquipment = async (page?: number) => {
    if (!userStore.user) return;

    try {
      _setIsLoading(true);

      let req = {
        ...request,
        limit: limit,
        page: page || request.page,
      };
      const [{ meta, data }] = await Promise.all([EquipmentService.list(req), facilityStore.fetchFacility(facilityId)]);
      setEquipment(data);
      setEquipmentMetadata(meta);
      _setCurrentPage(meta.current_page);

      const activeMessage = filters.is_active === 'true' ? 'active' : filters.is_active === 'false' ? 'inactive' : '';
      const meteredMessage =
        filters.is_metered === 'true' ? 'metered' : filters.is_metered === 'false' ? 'non-metered' : '';
      setNoResultsMessage(`No ${activeMessage} ${meteredMessage} equipment found.`);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listEquipment.error);
    } finally {
      setShouldRefresh(false);
      setIsFiltering(false);
      setIsSearching(false);
      _setIsLoading(false);
    }
  };

  const search = (query?: string) => {
    setRequest({
      ...request,
      page: 1,
      name: query,
    });
    setIsSearching(true);
  };

  const applyFilters = () => {
    const req = { ...request, ...filters };
    setRequest(req);
    setIsFiltering(true);
    setIsFiltersMenuOpen(false);
  };

  const clearFilters = () => {
    setRequest({ facility_id: facilityId, page: 1 });
    setFilters({ ...defaultFilters.current, is_active: '' });
    setIsFiltering(true);
    setIsFiltersMenuOpen(false);
  };

  const _fetchEquipment = propFetchEquipment ?? fetchEquipment;

  /** Effects **/
  useEffect(() => {
    if (shouldRefresh) {
      _fetchEquipment().finally(() => setShouldRefresh(false));
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (isSearching || isFiltering) {
      _fetchEquipment();
    }
  }, [isSearching, isFiltering]);

  useEffect(() => {
    if (!_equipment) {
      if (!!Object.keys(filters).length) applyFilters();
      else _fetchEquipment(_currentPage);
    }
  }, [_equipment, _currentPage, filters]);

  useEffect(() => {
    if (!_isLoading && _metadata && _currentPage !== _metadata.current_page) {
      _setIsLoading(true);
      _fetchEquipment(_currentPage);
    }
  }, [_isLoading, _metadata, _currentPage]);

  /** Render **/
  return showEditMetered && facilityStore.facility ? (
    <UpdateMultipleMeteredModal
      facility={facilityStore.facility}
      setIsVisible={setShowEditMetered}
      setShouldRefresh={(_shouldRefresh) => {
        _setIsLoading(true);
        setShouldRefresh(_shouldRefresh);
      }}
    />
  ) : (
    <Box fill="horizontal">
      <Box row gap="medium">
        <Card {...boxProps}>
          {!hideHeader && (
            <CardHeader title={title || 'Equipment List'} icon={<EquipmentIcon size="24" />}>
              <Box direction="row" gap="small">
                {showSearchInput && searchQuery !== undefined && setSearchQuery !== undefined && (
                  <SearchInput
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    onSearch={search}
                    isSearching={isSearching}
                  />
                )}
                <DropButton
                  plain
                  icon={<Filter color={isFiltersMenuHovered || isFiltersMenuOpen ? 'brand' : undefined} />}
                  open={isFiltersMenuOpen}
                  onOpen={() => setIsFiltersMenuOpen(true)}
                  onClose={() => setIsFiltersMenuOpen(false)}
                  onMouseOver={() => setIsFiltersMenuHovered(true)}
                  onMouseOut={() => setIsFiltersMenuHovered(false)}
                  tip="Filter Equipment"
                  dropAlign={{ right: 'right', top: 'bottom' }}
                  dropContent={
                    <FiltersDropdown
                      isFiltering={isFiltering}
                      onSubmit={() => applyFilters()}
                      onClear={() => clearFilters()}
                      filters={[
                        {
                          label: 'Activity Status',
                          value: filters.is_active ?? '',
                          setValue: (value) => setFilters({ ...filters, is_active: value || undefined }),
                          options: [
                            { label: 'All', value: '' },
                            { label: 'Active', value: 'true' },
                            { label: 'Retired', value: 'false' },
                          ],
                        },
                        {
                          label: 'Meter Type',
                          value: filters.is_metered ?? '',
                          setValue: (value) => setFilters({ ...filters, is_metered: value || undefined }),
                          options: [
                            { label: 'All', value: '' },
                            { label: 'Metered', value: 'true' },
                            { label: 'Unmetered', value: 'false' },
                          ],
                        },
                      ]}
                    />
                  }
                />
                <Menu
                  icon={<MenuIcon color={isMenuHovered || isMenuOpen ? 'brand' : undefined} />}
                  onMouseOver={() => setIsMenuHovered(true)}
                  onMouseOut={() => setIsMenuHovered(false)}
                  tip="Equipment Actions"
                  dropAlign={{ top: 'bottom', right: 'right' }}
                  items={[
                    { label: 'Edit Metered', onClick: () => setShowEditMetered(true) },
                    { label: 'Edit Unmetered', onClick: () => setShowEditUnmetered(true), disabled: isCanada },
                    {
                      label: 'Add Equipment',
                      onClick: () =>
                        !!facilityId ? setShowEquipmentModal(true) : navigate(`/clients/${clientId}/equipment/create`),
                    },
                  ].filter((item) => showAddButton || !item.label.includes('Add'))}
                />
              </Box>
            </CardHeader>
          )}
          <CardBody pad="none" gap="none">
            <Box elevation="small">
              {(_isLoading || isSearching || isFiltering) && <LoadingSpinner />}
              {!_isLoading && !isSearching && !isFiltering && (
                <DataTable
                  id="equipment_list_data_table"
                  pad={{ horizontal: '1.5rem' }}
                  columns={[
                    {
                      property: 'id',
                      primary: true,
                      size: '4%',
                      render: (equipment) => (
                        <Box pad={{ vertical: pxToRem(23) }}>
                          <Link to={`/clients/${equipment.client_id}/equipment/${equipment.id}`}>
                            <Box row borderRadius="6px" justify="center">
                              <Tip content="View Equipment details">
                                <SettingsOption size="16px" color="accent-1" />
                              </Tip>
                            </Box>
                          </Link>
                        </Box>
                      ),
                    },
                    {
                      property: 'equipment_type.name',
                      size: '17%',
                      header: <DataTableHeader title="CHARGING TYPE" />,
                      render: (equipment) => <DataTableItem value={equipment.equipment_type.name} />,
                    },
                    {
                      property: 'is_active',
                      header: <DataTableHeader title="STATUS" />,
                      render: (equipment) => (
                        <DataTableItem value={equipment.is_active ? EntityStatus.Active : EntityStatus.Retired} />
                      ),
                    },
                    {
                      property: 'is_metered',
                      size: '8%',
                      align: 'center',
                      header: <DataTableHeader title="METER" />,
                      render: (equipment) => (
                        <Box row>
                          {equipment.is_metered && (
                            <Tip content="Metered Equipment">
                              <Dashboard size="20px" />
                            </Tip>
                          )}
                        </Box>
                      ),
                    },
                    {
                      property: 'serial_number',
                      size: '13%',
                      header: <DataTableHeader title="SERIAL / VIN" />,
                      render: (equipment) => <DataTableItem value={equipment.serial_number} />,
                    },
                    {
                      property: 'manufacturer',
                      size: '12%',
                      header: <DataTableHeader title="MANUFACTURER" />,
                      render: (equipment) => <DataTableItem value={equipment.manufacturer} />,
                    },
                    {
                      property: 'model_year',
                      size: '11%',
                      header: <DataTableHeader title="MODEL YEAR" />,
                      render: (equipment) => <DataTableItem value={equipment.model_year} />,
                    },
                    {
                      property: 'model_number',
                      size: '13%',
                      header: <DataTableHeader title="MODEL NUMBER" />,
                      render: (equipment) => <DataTableItem value={equipment.model_number ?? ''} />,
                    },
                    {
                      property: 'unit_number',
                      size: '11%',
                      header: <DataTableHeader title="UNIT NO." />,
                      render: (equipment) => <DataTableItem value={equipment.unit_number ?? ''} />,
                    },
                    {
                      property: 'equipment_usages.total_kwh',
                      size: '12%',
                      header: (
                        <DataTableHeader>
                          <Box align="center">
                            <Text size="small">TOTAL kWh</Text>
                            {!!equipment?.length && !!equipment[0].equipment_usages?.length && (
                              <Text size="small">
                                (
                                {getPeriodFromDateString(
                                  equipment[0].equipment_usages[0].start_reporting_quarter ?? '',
                                  !!isCanada
                                )}
                                )
                              </Text>
                            )}
                          </Box>
                        </DataTableHeader>
                      ),
                      render: (equipment) =>
                        equipment.equipment_usages[0] ? (
                          <DataTableItem
                            value={formatNumber(equipment.equipment_usages[0].total_kwh, true, false, false, 4)}
                            unitName="kWh"
                          />
                        ) : (
                          <DataTableItem value="-" />
                        ),
                    },
                  ]}
                  data={_equipment}
                  sortable
                  background={['light-6', 'white']}
                  border={{ color: 'light-2', side: 'bottom', size: 'small' }}
                  // border={{size: '0.5px'}}
                />
              )}
              {!hasEquipment && !_isLoading && !isSearching && (
                <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
                  <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                    {noResultsMessage}
                  </Text>
                </Box>
              )}
            </Box>
            {hasEquipment && !!_metadata && !!_currentPage && !!_setCurrentPage && (
              <Box pad="1.5rem" flex="grow">
                <Pagination
                  alignSelf="center"
                  size="small"
                  page={_currentPage}
                  step={_metadata?.per_page}
                  numberItems={_metadata?.total}
                  onChange={(e) => _setCurrentPage(e.page)}
                />
              </Box>
            )}
          </CardBody>
        </Card>
        {showFilters && (
          <Filters
            isFiltering={isFiltering}
            onSubmit={() => applyFilters()}
            onClear={() => clearFilters()}
            filters={[]}
          />
        )}
      </Box>
      {!!clientId && !!facilityId && (
        <AddEquipmentModal
          clientId={clientId}
          facilityId={facilityId}
          isVisible={showEquipmentModal}
          setIsVisible={setShowEquipmentModal}
        />
      )}
      {!!clientId && !!facilityStore.facility && (
        <UpdateMultipleUnmeteredModal
          facility={facilityStore.facility}
          isVisible={showEditUnmetered}
          setIsVisible={setShowEditUnmetered}
          refreshData={_fetchEquipment}
        />
      )}
    </Box>
  );
};

export type EquipmentListProps = BoxProps & {
  clientId: TEquipmentId;
  facilityId: TFacilityId;
  firstReportingQuarter?: DateString;
  lastReportingQuarter?: Nullable<DateString>;
  isCanada?: boolean;
  title?: string;
  equipment?: Equipment[];
  fetchEquipment?: (page?: number) => Promise<void>;
  metadata?: TResponseMetadata;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  showAddButton?: boolean;
  showBulkUpdateButton?: boolean;
  showFilters?: boolean;
  showSearchInput?: boolean;
  hideHeader?: boolean;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
};
