import { MenuProps, ResponsiveContext } from 'grommet';
import React, { PropsWithChildren, useContext } from 'react';
import { PageHeader } from './PageHeader';
import { Box, LoadingSpinner } from '/src/components/common';
import { DesktopLayout } from '/src/layouts';
import { TClientId } from '/src/lib/models';
import { getIsMobile } from '/src/utils';

export type FormPageProps = PropsWithChildren & {
  isLoading?: boolean;
  breadcrumbNav?: React.ReactNode;
  userInfoBar?: React.ReactNode;
  hideMenuButton?: boolean;
  menuButtonItems?: MenuProps['items'];
  title?: string;
  titleBar?: React.ReactNode;
  titleLinkUrl?: string;
  titleLinkIconSize?: string;
  isUserPage?: boolean;
  isClientPage?: boolean;
  isDetailPage?: boolean;
  clientId?: TClientId;
};

export const FormPage: React.FC<FormPageProps> = (props) => {
  const {
    children,
    isLoading,
    breadcrumbNav,
    userInfoBar,
    hideMenuButton,
    menuButtonItems,
    title,
    titleBar,
    titleLinkUrl,
    titleLinkIconSize,
    isUserPage,
    isClientPage,
    isDetailPage,
    clientId,
  } = props;

  const screenSize = useContext(ResponsiveContext);
  const isMobile = getIsMobile(screenSize);

  return (
    <DesktopLayout>
      {isLoading && <LoadingSpinner size="large" />}
      {!isLoading && (
        <>
          {breadcrumbNav}
          <Box
            flex="grow"
            pad={{
              horizontal: isMobile ? '3.75rem' : '8rem',
              vertical: isMobile ? '2.25rem' : '3rem',
            }}
          >
            {!!title && (
              <PageHeader
                title={title}
                titleBar={titleBar}
                isUserPage={isUserPage}
                isClientPage={isClientPage}
                clientId={clientId}
                showLinkButton={!!titleLinkUrl}
                linkButtonUrl={titleLinkUrl}
                linkButtonSize={titleLinkIconSize}
                showMenuButton={!hideMenuButton}
                menuButtonItems={menuButtonItems}
              />
            )}
            {userInfoBar}
            <Box direction={isMobile || isDetailPage ? 'column' : 'row'} gap="1rem">
              {children}
            </Box>
          </Box>
        </>
      )}
    </DesktopLayout>
  );
};
