import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientService } from '/src/api';
import { BreadcrumbNav, ListPage, PageHeader, StatementList } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';

export const ListStatementsPage = observer(() => {
  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const params = useParams();
  const clientId = parseInt(params.client_id ?? '');

  /** State **/
  const [clientName, setClientName] = useState('');

  /** Computed **/
  const isLoading = !clientName;

  /** Effects **/
  useEffect(() => {
    if (!clientName && userStore.user) {
      if (userStore.isFacilityUser) {
        setClientName(userStore.user.clients.find((client) => client.id === clientId)?.name ?? '');
      } else {
        ClientService.get({ id: clientId })
          .then((client) => setClientName(client.name))
          .catch((err) => globalStore.handleApiError(err, toastMessages.listIncentiveStatements.error));
      }
    }
  }, [clientName]);

  /** Render **/
  return (
    <ListPage
      title={clientName}
      pageHeader={<PageHeader title={clientName} />}
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
            { name: clientName, link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
          ]}
          currentPageName="Statements"
        />
      }
      isLoading={isLoading}
    >
      <StatementList clientId={clientId} />
    </ListPage>
  );
});
