import { BasicSelectProps, Keyboard, Select as SelectInput, SelectMultiple, Stack } from 'grommet';
import React, { useState } from 'react';
import { Box, BoxProps, Input, Line, Text } from '/src/components';
import { SelectOption } from '/src/lib/types';
import { pxToRem } from '/src/utils';

export type SelectProps = BoxProps & {
  label: string;
  value?: string | number | null | undefined;
  setValue?: (value: any) => void;
  options: (SelectOption<string | number | null | undefined> | string)[];
  onMore?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  onSearch?: (query: string) => void;
  onChange?: (props: any) => void;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
  error?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  fuseAdminOnly?: boolean;
  hideOptionalText?: boolean;
  hideRequiredMarker?: boolean;
  hideLabel?: boolean;
  disabled?: BasicSelectProps['disabled'];
  multiple?: boolean;
  values?: string[] | number[];
  setValues?: (values: any) => void;
  searchPlaceholder?: string;
  emptySearchMessage?: string;
  labelGap?: boolean;
};

export const Select: React.FC<SelectProps> = ({ children, ...props }) => {
  const {
    label,
    value,
    setValue,
    error,
    id,
    name,
    onChange,
    onSubmit,
    placeholder,
    required,
    fuseAdminOnly,
    hideOptionalText,
    hideRequiredMarker,
    hideLabel,
    disabled,
    onOpen,
    onClose,
    onMore,
    onSearch,
    options,
    multiple,
    values,
    setValues,
    searchPlaceholder = 'Search by name...',
    emptySearchMessage = 'No matches found.',
    labelGap,
    ...boxProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  let disabledValue: string | undefined;

  if (disabled) {
    const option = options.find((o) => {
      if (typeof o === 'object') {
        return o.value === value;
      } else {
        return o === value;
      }
    });
    if (typeof option === 'object') disabledValue = option.label;
  }

  const renderSelect = () => (
    <SelectInput
      id={id}
      name={name || id}
      placeholder={placeholder || `Choose...`}
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      value={value ?? undefined}
      onChange={onChange ? onChange : ({ value: nextValue }) => (setValue ? setValue(nextValue) : undefined)}
      plain
      style={{
        fontFamily: 'Lato, sans-serif',
        height: pxToRem(58),
        paddingTop: pxToRem(26),
        paddingBottom: pxToRem(10),
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMore={onMore}
      onOpen={onOpen}
      onClose={onClose}
      onSearch={onSearch}
      options={options}
      required={required}
      disabled={disabled}
      searchPlaceholder={searchPlaceholder}
      emptySearchMessage={emptySearchMessage}
    />
  );

  const isTotallyDisabled = typeof disabled === 'boolean' ? disabled : disabled?.length === options.length;

  const renderSelectMultiple = () => (
    <SelectMultiple
      id={id}
      name={name || id}
      placeholder={placeholder || `Choose...`}
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      value={values}
      onChange={({ value: nextValues }) => (setValues ? setValues(nextValues) : undefined)}
      plain
      style={{
        fontFamily: 'Lato, sans-serif',
        height: pxToRem(58),
        paddingTop: pxToRem(26),
        paddingBottom: pxToRem(10),
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMore={onMore}
      onOpen={onOpen}
      onClose={onClose}
      onSearch={onSearch}
      options={options}
      required={required}
      disabled={disabled}
      searchPlaceholder={searchPlaceholder}
      emptySearchMessage={emptySearchMessage}
    />
  );

  const renderSelectComponent = () => {
    return <Keyboard onEnter={onSubmit}>{multiple ? renderSelectMultiple() : renderSelect()}</Keyboard>;
  };

  const borderColor = error ? 'red' : isFocused ? 'accent-1' : 'light-5';

  return !!disabled && !multiple ? (
    <Box {...boxProps}>
      <Input
        id={id}
        name={name || id}
        label={label}
        value={disabledValue ?? value ?? ''}
        setValue={() => {}}
        placeholder={placeholder}
        required={required}
        hideOptionalText={hideOptionalText}
        disabled
      />
    </Box>
  ) : (
    <Box {...boxProps}>
      <Box
        border={isTotallyDisabled ? undefined : { color: borderColor, size: 'small' }}
        background={isTotallyDisabled ? 'light-6' : undefined}
        borderRadius="6px"
      >
        {!hideLabel ? (
          <Stack anchor="top-left" guidingChild="last" fill="vertical">
            <Box pad={{ top: 'xsmall', left: 'small' }}>
              <Text size="small" color={isTotallyDisabled ? 'light-3' : 'accent-1'}>
                {label}
                {required && !hideRequiredMarker && '*'}
              </Text>
            </Box>
            <Box pad={{ top: labelGap ? 'xsmall' : undefined }}>{renderSelectComponent()}</Box>
          </Stack>
        ) : (
          <Box>{renderSelectComponent()}</Box>
        )}
      </Box>
      {(error || fuseAdminOnly) && (
        <Box row gap="small" margin={{ top: '0.5rem', left: '0.75rem' }}>
          {error && (
            <Text color="red" size="0.75rem">
              {error}
            </Text>
          )}
          {error && fuseAdminOnly && <Line direction="vertical" margin="0" />}
          {fuseAdminOnly && (
            <Text color="dark-1" size="0.75rem">
              FuSE ADMINS ONLY
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};
