import { DateString, FloatString, Nullable } from '../types';
import {
  Client,
  EquipmentType,
  EquipmentUsage,
  FSERegistrationStatus,
  Facility,
  Program,
  TClientId,
  TEquipmentTypeId,
  TFSERegistrationStatusId,
  TFacilityId,
  TProgramId,
  TReportingPeriodTypeId,
} from '/src/lib/models';

export type TEquipmentId = number;

export interface Equipment {
  charging_level: string;
  charging_usage_type: string;
  client_id: TClientId;
  client?: Client;
  comments: Nullable<string>;
  connector_type: string;
  duplicate_of_equipment: Nullable<string>;
  equipment_type_id: TEquipmentTypeId;
  equipment_type: EquipmentType;
  equipment_usages: EquipmentUsage[];
  facility_id: TFacilityId;
  facility?: Facility;
  first_active_reporting_quarter: DateString;
  first_day_in_service: Nullable<DateString>;
  fse_id: Nullable<string>;
  fse_registration_status_id: TFSERegistrationStatusId;
  fse_registration_status: FSERegistrationStatus;
  fse_ru: Nullable<string>;
  id: TEquipmentId;
  is_active: boolean;
  is_finalized: boolean;
  is_metered: boolean;
  last_active_reporting_quarter: Nullable<DateString>;
  latitude: FloatString;
  longitude: FloatString;
  manufacturer: string;
  model_number: Nullable<string>;
  model_year: number;
  name: Nullable<string>;
  program_id: TProgramId;
  program?: Program;
  rated_capacity: string;
  reporting_period_type_id?: TReportingPeriodTypeId;
  serial_number: string;
  unit_number: Nullable<string>;
  used_for_charging: string;
}
