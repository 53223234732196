import { TextInput, TextInputProps } from 'grommet';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Box, Text } from '/src/components';
import {
  DataTableCellUnit,
  FloatString,
  MeteredEquipmentUsageDataForm,
  UnmeteredEquipmentUsageDataForm,
} from '/src/lib/types';
import { formatNumber as formatNumberUtil } from '/src/utils';

export type UsagesDataTableCellProps = {
  index: number;
  width: string | number;
  isEditable?: boolean;
  valueKey?: string;
  formValues?: UnmeteredEquipmentUsageDataForm | MeteredEquipmentUsageDataForm;
  updateFormValue?: (key: string, value: string) => void;
  inputType?: TextInputProps['type'];
  inputMode?: TextInputProps['inputMode'];
  unit?: DataTableCellUnit;
  formatNumber?: boolean;
};

export const UsagesDataTableCell: React.FC<UsagesDataTableCellProps> = observer((props) => {
  const {
    index,
    width,
    isEditable,
    valueKey,
    formValues,
    updateFormValue,
    inputType = 'number',
    inputMode = 'decimal',
    unit,
    formatNumber,
  } = props;

  const value =
    valueKey && (typeof formValues?.[valueKey] === 'string' || typeof formValues?.[valueKey] === 'number')
      ? formValues[valueKey]
      : '-';

  const canEdit = isEditable && !!valueKey && !!formValues && !!updateFormValue;

  return (
    <Box
      key={index}
      width={typeof width === 'number' ? width.toString() : width}
      align="center"
      justify="center"
      border={{ side: 'all', size: '0.4px', color: 'light-2' }}
      pad="small"
      gap="xsmall"
      row
    >
      {!canEdit && (
        <Box height="2.25rem" justify="center">
          <Text toUpperCase>{formatNumber ? formatNumberUtil(value, true) || '-' : value}</Text>
        </Box>
      )}
      {canEdit && (
        <TextInput
          id={valueKey}
          name={valueKey}
          value={formValues[valueKey] as FloatString}
          type={inputType}
          inputMode={inputMode}
          textAlign="center"
          onChange={(e) => (valueKey ? updateFormValue(valueKey, e.target.value) : undefined)}
          className="hide-input-arrows"
          height="2.25rem"
          style={{
            background: !canEdit ? 'light-2' : 'white',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        />
      )}
      {unit && (
        <Text>
          {unit === 'percent' && '%'}
          {unit === 'kwh' && 'kWh'}
        </Text>
      )}
    </Box>
  );
});
