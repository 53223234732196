import { BasicSelectProps, SelectExtendedProps } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ApiError, ProgramService } from '/src/api';
import { Box, BoxProps, Select } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Program, TProgramId, TRegionId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { Nullable, SelectOption } from '/src/lib/types';

export type ProgramSelectProps = {
  value?: Nullable<number>;
  setValue: (index: number) => void;
  programId?: TProgramId;
  programs?: Program[];
  error?: string;
  required?: boolean;
  regionId?: Nullable<TRegionId>;
  hideOptionalText?: boolean;
  hideLabel?: boolean;
  emptySearchMessage?: string;
  searchPlaceholder?: string;
  style?: SelectExtendedProps['style'];
  fill?: BoxProps['fill'];
  width?: BoxProps['width'];
  label?: string;
  placeholder?: string;
  id?: string;
  name?: string;
  disabled?: BasicSelectProps['disabled'];
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
  filterOutOptions?: TProgramId[];
  options?: SelectOption<TProgramId>[];
};

export const ProgramSelect: React.FC<ProgramSelectProps> = observer((props) => {
  const {
    programs,
    value,
    setValue,
    programId,
    error,
    required,
    fill,
    width,
    hideOptionalText,
    hideLabel,
    emptySearchMessage,
    searchPlaceholder,
    label,
    placeholder,
    regionId,
    id,
    name,
    disabled,
    onSubmit,
    filterOutOptions,
    options,
  } = props;

  /** Stores **/
  const globalStore = useGlobalStore();
  const userStore = useUserStore();

  /** State **/
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(true);
  const [programsList, setProgramsList] = useState<Program[]>([]);
  const [defaultSelectOptions, setDefaultSelectOptions] = useState<SelectOption<TProgramId>[]>([]);
  const [selectOptions, setSelectOptions] = useState<SelectOption<TProgramId>[]>([]);

  /** Methods **/
  const fetchProgramsList = async (region_id: TRegionId) => {
    if (!userStore.user) return;

    try {
      setIsLoadingPrograms(true);

      const programs = await ProgramService.list({ region_id });
      setProgramsList(programs);

      let options = programs.map((program) => ({
        label: program.name,
        value: program.id,
      }));

      if (filterOutOptions) {
        options = options.filter(({ value }) => !filterOutOptions.includes(value));
      }

      setSelectOptions(options);
      setDefaultSelectOptions(options);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listPrograms.error);
    } finally {
      setIsLoadingPrograms(false);
    }
  };

  const filterPrograms = (query: string) => {
    if (query === '') setSelectOptions(defaultSelectOptions);
    const exp = new RegExp(query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    const filteredOptions = defaultSelectOptions.filter(({ label }) => exp.test(label));
    setSelectOptions(filteredOptions);
  };

  /** Effects **/
  useEffect(() => {
    if (isLoadingPrograms) {
      if (!programsList.length) {
        if (programs) {
          setProgramsList(programs);
          const options = programs.map((program) => ({
            label: program.name,
            value: program.id,
          }));
          setSelectOptions(options);
          setDefaultSelectOptions(options);
        } else if (regionId) {
          fetchProgramsList(regionId);
        }
      } else {
        setIsLoadingPrograms(false);
      }
    }
  }, [programs, programsList, regionId, isLoadingPrograms, value]);

  useEffect(() => {
    if (regionId) {
      setValue(NaN);
      fetchProgramsList(regionId);
    }
  }, [regionId]);

  return (
    <Select
      id={id}
      name={id ? undefined : name || 'selected_program'}
      label={label ?? 'Program'}
      placeholder={placeholder}
      hideLabel={hideLabel}
      options={options ?? selectOptions}
      onOpen={() => filterPrograms('')}
      value={!isLoadingPrograms ? value : ''}
      fill={fill}
      width={width}
      setValue={setValue}
      error={error}
      required={required}
      disabled={disabled}
      onSearch={selectOptions.length > 1 ? (query) => filterPrograms(query) : undefined}
      emptySearchMessage={emptySearchMessage}
      searchPlaceholder={searchPlaceholder}
      onSubmit={onSubmit}
      hideOptionalText={hideOptionalText}
    />
  );
});
