import { TListResponse } from '.';
import {
  Client,
  ClientAddress,
  ClientName,
  ProgramCommission,
  TBankAccountTypeId,
  TClientId,
  TRegionId,
  TRemittanceMethodId,
  TRoleId,
  User,
} from '/src/lib/models';
import { DateString, Nullable, ProgramCommissionDataForm, TResponseMetadata } from '/src/lib/types';

/**
 * List Clients
 */
export type TListClientsRequest = {
  limit?: number;
  page?: number;
  name?: string;
  is_active?: boolean;
};

export type TListClientsResponse = TListResponse<Client[]>;

/**
 * List Client Addresses
 */
export type TListClientAddressesResponse = ClientAddress[];

/**
 * List Client Names
 */
export type TListClientNamesResponse = ClientName[];

/**
 * List Client Users
 */
export type TListClientUsersRequest = {
  id: TClientId;
  page?: number;
  name?: string;
  role_id?: TRoleId;
  is_active?: boolean;
};

export type TListClientUsersResponse = TListResponse<User[]>;

/**
 * Get Client
 */
export type TGetClientRequest = {
  id: TClientId;
};

export type TGetClientResponse = Client;

/**
 * Create Client
 */
export type TCreateClientRequest = {
  name: string;
  start_date: DateString;
  website: Nullable<string>;
  business_id: Nullable<string>;
  first_active_reporting_quarter: DateString;
  remittance_method_id: TRemittanceMethodId;
  bank_name: Nullable<string>;
  bank_account_type_id: Nullable<TBankAccountTypeId>;
  routing_number: Nullable<string>;
  account_number: Nullable<string>;
  jumpstart_amount: Nullable<string>;
  comments: Nullable<string>;
  hq_address_line1: string;
  hq_address_line2: Nullable<string>;
  hq_address_city: string;
  hq_address_region_id: TRegionId;
  hq_address_post_code: string;
  remittance_address_line1: Nullable<string>;
  remittance_address_line2: Nullable<string>;
  remittance_address_city: Nullable<string>;
  remittance_address_region_id: Nullable<TRegionId>;
  remittance_address_post_code: Nullable<string>;
  programs: ProgramCommissionDataForm[];
};

export type TCreateClientResponse = Client;

/**
 * Update Client
 */
export type TUpdateClientRequest = {
  id: TClientId;
  name: string;
  end_date: Nullable<DateString>;
  website: Nullable<string>;
  business_id: string;
  remittance_method_id: TRemittanceMethodId;
  bank_name: Nullable<string>;
  bank_account_type_id: Nullable<TBankAccountTypeId>;
  routing_number: Nullable<string>;
  account_number: Nullable<string>;
  jumpstart_amount: Nullable<string>;
  comments: Nullable<string>;
  hq_address_line1: string;
  hq_address_line2: Nullable<string>;
  hq_address_city: string;
  hq_address_region_id: TRegionId;
  hq_address_post_code: string;
  remittance_address_line1: Nullable<string>;
  remittance_address_line2: Nullable<string>;
  remittance_address_city: Nullable<string>;
  remittance_address_region_id: Nullable<TRegionId>;
  remittance_address_post_code: Nullable<string>;
};

export type TUpdateClientResponse = Client;
