import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from './Box';

export type InfoBoxProps = PropsWithChildren & {
  gap?: BoxProps['gap'];
  pad?: BoxProps['pad'];
};

export const InfoBox: React.FC<InfoBoxProps> = ({ children, ...props }) => {
  const { gap, pad } = props;

  return (
    <Box
      pad={pad || '1rem'}
      gap={gap || '0.5rem'}
      background="light-6"
      border={{ size: 'small', color: 'light-5' }}
      borderRadius="6px"
      direction="row"
      align="center"
      fontFamily="Lato, sans-serif"
    >
      {children}
    </Box>
  );
};
