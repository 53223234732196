import { action, computed, makeObservable, observable } from 'mobx';

import { ClientReportingPeriodService, ClientService, TListClientReportingPeriodsRequest } from '/src/api';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import { Client, ClientReportingPeriod, TClientId, TReportingPeriodTypeId } from '/src/lib/models';
import { DateString, SelectOption, SelectOptions, StorageKeys } from '/src/lib/types';
import { filterSelectOptions, getQuarterFromDateString, Storage } from '/src/utils';
import storage from '/src/utils/storage';

const allClientsOption = { label: 'All Clients', value: 0 };

export default class ClientStore extends BaseStore {
  defaultClientOptions: SelectOptions<TClientId> = [];
  clientOptions?: SelectOptions<TClientId>;
  selectedClient?: Client;
  _selectedClientId?: TClientId;

  // activeClient?: Client;
  _activeClientId?: TClientId;

  reportingPeriods: ClientReportingPeriod[] = [];
  reportingPeriodOptions?: SelectOptions<DateString>;
  reportingPeriodsClientId?: TClientId;
  selectedReportingPeriod?: ClientReportingPeriod;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      defaultClientOptions: observable,
      clientOptions: observable,
      selectedClient: observable,
      _selectedClientId: observable,
      // activeClient: observable,
      _activeClientId: observable,
      reportingPeriods: observable,
      reportingPeriodOptions: observable,
      reportingPeriodsClientId: observable,
      selectedReportingPeriod: observable,
      nextReportingPeriod: computed,
      previousReportingPeriod: computed,
      selectedClientId: computed,
      selectedClientName: computed,
      selectedClientUsers: computed,
      restoreSelectedClientId: action,
      restoreActiveClientId: action,
      filterClientOptions: action,
      fetchClientOptions: action,
      setSelectedClient: action,
      setSelectedClientId: action,
      // setActiveClient: action,
      setActiveClientId: action,
      setClientOptions: action,
      setDefaultClientOptions: action,
      setReportingPeriods: action,
      setReportingPeriodOptions: action,
      setReportingPeriodsClientId: action,
      setSelectedReportingPeriod: action,
      fetchClientReportingPeriods: action,
    });
  }

  get nextReportingPeriod() {
    const index = this.reportingPeriods.findIndex((period) => period.id === this.selectedReportingPeriod?.id);
    if (typeof index === 'number') return this.reportingPeriods[index - 1];
  }

  get previousReportingPeriod() {
    const index = this.reportingPeriods.findIndex((period) => period.id === this.selectedReportingPeriod?.id);
    if (typeof index === 'number') return this.reportingPeriods[index + 1];
  }

  get allClientsOption() {
    return { label: 'All Clients', value: 0 };
  }

  get selectedClientName() {
    return this.selectedClient?.name;
  }

  get selectedClientUsers() {
    return this.selectedClient?.users;
  }

  get selectedClientId() {
    if (!this._selectedClientId) {
      this.restoreSelectedClientId();
    }
    return this._selectedClientId;
  }

  get activeClient() {
    return this.rootStore.userStore.getUserClient(this.activeClientId);
  }

  get activeClientId() {
    if (!this._activeClientId) this.restoreActiveClientId();
    return this._activeClientId;
  }

  set selectedClientId(selectedClientId) {
    this._selectedClientId = selectedClientId;
  }

  restoreSelectedClientId = () => {
    this._selectedClientId = storage.get(StorageKeys.SelectedClientID);
  };

  restoreActiveClientId = () => {
    this._activeClientId = storage.get(StorageKeys.ActiveClientId);
  };

  filterClientOptions = (nameFilter: string) => {
    this.clientOptions = filterSelectOptions(nameFilter, this.defaultClientOptions);
  };

  fetchClientOptions = async () => {
    const userStore = this.rootStore.userStore;
    if (!userStore.user) return;

    const clientNames = userStore.user?.role.is_internal
      ? await ClientService.listClientNames()
      : userStore.user?.clients ?? [];

    let options = clientNames.map(({ name, id }) => ({
      label: name,
      value: id,
    }));

    if (userStore.isFuseAdmin) options = [allClientsOption, ...options];

    this.setClientOptions(options);
    this.setDefaultClientOptions(options);
    if (options.length && !this.selectedClientId) this.selectedClientId = options[0]?.value;
  };

  setClientOptions = (options: SelectOption<TClientId>[]) => {
    this.clientOptions = options;
    if (options.length && !this.selectedClientId) this.selectedClientId = options[0]?.value;
  };

  setDefaultClientOptions = (options: SelectOption<TClientId>[]) => {
    this.defaultClientOptions = options;
  };

  setSelectedClient = (client?: Client) => {
    this.selectedClient = client;
  };

  setSelectedClientId = (clientId?: TClientId) => {
    this._selectedClientId = clientId;
  };

  // setActiveClient = (client?: Client) => {
  //   this.activeClient = client;
  //   this.setActiveClientId(client?.id);
  // };

  setActiveClientId = (clientId?: TClientId) => {
    this._activeClientId = clientId;
    Storage.set(StorageKeys.ActiveClientId, clientId);
  };

  setReportingPeriods = (reportingPeriods?: ClientReportingPeriod[]) => {
    this.reportingPeriods = reportingPeriods ?? [];
  };

  setReportingPeriodOptions = (reportingPeriodOptions?: SelectOptions<DateString>) => {
    this.reportingPeriodOptions = reportingPeriodOptions;
  };

  setReportingPeriodsClientId = (clientId?: TClientId) => {
    this.reportingPeriodsClientId = clientId;
  };

  setSelectedReportingPeriod = (selectedReportingPeriod?: ClientReportingPeriod) => {
    this.selectedReportingPeriod = selectedReportingPeriod;
  };

  fetchClientReportingPeriods = async (
    clientId: TClientId,
    reportingPeriodTypeId?: TReportingPeriodTypeId,
    showAll?: boolean
  ): Promise<ClientReportingPeriod[]> => {
    if (!this.rootStore.userStore.user) return [];

    const request: TListClientReportingPeriodsRequest = {
      client_id: clientId,
      limit: 1000,
      reporting_period_type_id: reportingPeriodTypeId,
    };

    if (!showAll) request.is_finalized = 0;

    const response = await ClientReportingPeriodService.list(request);

    const data = response.data.reverse();

    const options = data.map((period) => ({
      label:
        period.reporting_period_type_id === 1
          ? getQuarterFromDateString(period.start_reporting_quarter)
          : period.start_reporting_quarter.slice(0, 4),
      value: period.start_reporting_quarter,
    })) as SelectOptions<DateString>;

    this.setReportingPeriods(data);
    this.setReportingPeriodOptions(options);
    this.setReportingPeriodsClientId(clientId);

    return data;
  };
}
