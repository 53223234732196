import { Avatar, Button, Menu, MenuProps, ResponsiveContext, Tip } from 'grommet';
import { Add, Alert, CircleAlert, Filter, Lock, Menu as MenuIcon, Share } from 'grommet-icons';
import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton, Box, BoxProps, InfoBox, Text } from '/src/components';
import { useClientStore } from '/src/context';
import { TClientId } from '/src/lib/models';
import { getInitials, getIsMobile } from '/src/utils';
import { tooltipMessages } from '/src/lib/tooltips';

export type PageHeaderProps = PropsWithChildren &
  BoxProps & {
    title: string;
    titleBar?: React.ReactNode;
    showLinkButton?: boolean;
    linkButtonUrl?: string;
    linkButtonSize?: string;
    showAddButton?: boolean;
    addButtonLabel?: string;
    addButtonUrl?: string;
    addButtonIcon?: React.ReactNode;
    showMenuButton?: boolean;
    menuButtonItems?: MenuProps['items'];
    isUserPage?: boolean;
    isClientPage?: boolean;
    clientId?: TClientId;
    onAddButton?: () => void;
  };

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  /** Props **/
  const {
    title,
    titleBar,
    showLinkButton,
    linkButtonUrl,
    linkButtonSize,
    showAddButton,
    addButtonLabel,
    addButtonUrl,
    addButtonIcon,
    showMenuButton,
    menuButtonItems,
    isUserPage,
    isClientPage,
    clientId,
    onAddButton,
    children,
    ...boxProps
  } = props;

  /** Context */
  const navigate = useNavigate();
  const clientStore = useClientStore();
  const screenSize = useContext(ResponsiveContext);

  /** State */
  const [isButtonFocused, setIsButtonFocused] = useState(false);

  /** Memos */
  const isMobile = getIsMobile(screenSize);

  /** Render */
  if (isUserPage) {
    return (
      <Box direction="row" gap="small">
        <Box alignSelf="center">
          <Avatar background="light-3" size="80px" hoverIndicator={{ elevation: 'small' }}>
            <Text size="xxlarge" color="white">
              {getInitials(title)}
            </Text>
          </Avatar>
        </Box>
        <Box alignSelf="center" gap="0.25rem">
          <Text size="xxlarge">{title}</Text>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box direction="row" margin={{ bottom: '2rem' }} gap="1rem" align="center" {...boxProps}>
        <Box direction="row" gap="medium">
          <Text size="xxlarge">{title}</Text>
          {titleBar}
          {showLinkButton && !!linkButtonUrl && (
            <Button
              icon={<Share size={linkButtonSize || '16px'} />}
              onClick={() => navigate(linkButtonUrl)}
              tip={tooltipMessages.equipmentPageLink}
            />
          )}
        </Box>
        <Box flex="grow" />

        {showMenuButton && !!menuButtonItems && (
          <Menu
            dropAlign={{ right: 'right', top: 'bottom' }}
            icon={
              <Box
                pad="0.7rem"
                round
                border={{ size: 'small', color: 'light-2' }}
                elevation="small"
                background="white"
                align="center"
                justify="center"
              >
                <MenuIcon size="22px" />
              </Box>
            }
            items={menuButtonItems}
          />
        )}
        {showAddButton && (
          <Box
            alignSelf="end"
            background={isButtonFocused ? 'button-hover' : 'accent-1'}
            elevation={isButtonFocused ? 'small' : undefined}
            direction="row"
            pad="0.75rem"
            gap="xsmall"
            borderRadius="6px"
            onMouseOver={() => setIsButtonFocused(true)}
            onMouseOut={() => setIsButtonFocused(false)}
            onClick={() => {
              if (!!onAddButton) onAddButton();
              else if (addButtonUrl) navigate(addButtonUrl);
            }}
          >
            <Box alignSelf="center">{addButtonIcon ?? <Add size="16px" />}</Box>
            <Text alignSelf="center" color="white" size="medium" weight={500} toUpperCase>
              {addButtonLabel || 'ADD'}
            </Text>
          </Box>
        )}
        {isClientPage && clientId && (
          <AddButton
            background="accent-1"
            color="white"
            label="View Dashboard"
            onClick={() => {
              clientStore.setSelectedClientId(clientId);
              navigate('/');
            }}
          />
        )}
        {children}
      </Box>
    );
  }
};
