import { TProgramId } from '.';
import { DateString, Nullable } from '../types';

export type TProgramCommissionId = number;

export interface ProgramCommission {
  id: TProgramCommissionId;
  program_id: TProgramId;
  is_percent_rate: boolean;
  rate: number;
  start_reporting_quarter?: DateString;
}
